import { createRouter, createWebHistory } from 'vue-router'
import { useWebSiteStore } from './stores/web-site.store'

import DefaultLayout from './views/layouts/DefaultLayout.vue'
import AboutPage from './views/protected/AboutPage.vue'
import ActivationPage from './views/public/ActivationPage.vue'
import ProjectsPage from './views/protected/planner/ProjectsPage.vue'
import EquipmentsPage from './views/protected/planner/EquipmentsPage.vue'
import StaffsPage from './views/protected/planner/StaffsPage.vue'
import ListingsPage from './views/protected/planner/ListingsPage.vue'
import CompaniesPage from './views/protected/planner/CompaniesPage.vue'
import ProjectDetailsPage from './views/protected/planner/ProjectDetailsPage.vue'
import LoginPage from './views/public/LoginPage.vue'
import EventAgendaPage from './views/protected/event/EventAgendaPage.vue'
import EventSchedulerPage from './views/protected/event/EventSchedulerPage.vue'

export const menuRouteEnum = {
  HOME: 'menu.home',
  PROJECTS: 'menu.projects',
  EQUIPMENTS: 'menu.equipments',
  STAFFS: 'menu.staffs',
  COMPANIES: 'menu.companies',
  LISTINGS: 'menu.listings',
  EVENT_AGENDA: 'menu.event.agenda',
  EVENT_SCHEDULER: 'menu.event.scheduler',
  ABOUT: 'menu.about',
}

export const menuRoutes = {
  'menu.home': '/',
  'menu.projects': '/projects',
  'menu.equipments': '/equipments',
  'menu.staffs': '/staffs',
  'menu.companies': '/companies',
  'menu.listings': '/listings',
  'menu.event.agenda': '/events-agenda',
  'menu.event.scheduler': '/events-scheduler',
  'menu.about': '/about',
}

const routes = [
  {
    path: '/login', component: LoginPage, meta: { allowAny: true }
  },
  {
    path: '/activation', component: ActivationPage, meta: { allowAny: true }
  },
  {
    path: menuRoutes['menu.home'],
    component: DefaultLayout,
    children: [
      {
        path: '',
        redirect: to => {
          const webSiteStore = useWebSiteStore()
          return webSiteStore.getDecodedToken()?.user_type === webSiteStore.userTypes.Event ? menuRoutes[menuRouteEnum.EVENT_AGENDA] : menuRoutes[menuRouteEnum.PROJECTS]
        }
      },
      {
        name: 'projects',
        meta: { reference: menuRoutes[menuRouteEnum.PROJECTS] },
        path: menuRoutes[menuRouteEnum.PROJECTS],
        component: ProjectsPage,
      },
      {
        name: 'project-detail',
        meta: { reference: menuRoutes[menuRouteEnum.PROJECTS] },
        path: menuRoutes[menuRouteEnum.PROJECTS] + '/?:id',
        component: ProjectDetailsPage,
      },
      {
        name: 'equipments',
        meta: { reference: menuRoutes[menuRouteEnum.EQUIPMENTS] },
        path: menuRoutes[menuRouteEnum.EQUIPMENTS],
        component: EquipmentsPage,
      },
      {
        name: 'staffs',
        meta: { reference: menuRoutes[menuRouteEnum.STAFFS] },
        path: menuRoutes[menuRouteEnum.STAFFS],
        component: StaffsPage,
      },
      {
        name: 'companies',
        meta: { reference: menuRoutes[menuRouteEnum.COMPANIES] },
        path: menuRoutes[menuRouteEnum.COMPANIES],
        component: CompaniesPage,
      },
      {
        name: 'listings',
        meta: { reference: menuRoutes[menuRouteEnum.LISTINGS] },
        path: menuRoutes[menuRouteEnum.LISTINGS],
        component: ListingsPage,
      },
      {
        name: 'events-agenda',
        meta: { reference: menuRoutes[menuRouteEnum.EVENT_AGENDA] },
        path: menuRoutes[menuRouteEnum.EVENT_AGENDA],
        component: EventAgendaPage,
      },
      {
        name: 'events-scheduler',
        meta: { reference: menuRoutes[menuRouteEnum.EVENT_SCHEDULER] },
        path: menuRoutes[menuRouteEnum.EVENT_SCHEDULER],
        component: EventSchedulerPage,
      },
      { path: menuRoutes[menuRouteEnum.ABOUT], component: AboutPage },
    ]
  }
]

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

let firstTimeCheck = true

router.beforeEach(async(to, from, next) => {
  const webSiteStore = useWebSiteStore()
  if (firstTimeCheck && !to.meta.allowAny) {
    firstTimeCheck = false
    await webSiteStore.autoLoginAsync()
  }

  const isLogged = webSiteStore.refreshToken !== null && webSiteStore.accessToken !== null

  to.meta.allowAny || isLogged ? next() : next('/login')
})

export default router
