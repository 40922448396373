<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { useWebSiteStore } from '@/stores/web-site.store'

const i18n = useI18n()
const webSiteStore = useWebSiteStore()
const route = useRoute()

const props = defineProps({
  color: {
    type: String,
    default: 'primary'
  }
})

const breadcrumbEntries = computed(() => {
  const crumbs = []
  const menuEntries = webSiteStore.getMenuEntriesWithRoute()
  const currentMenuEntry = menuEntries.find(item => item.route === route.meta.reference)

  fillBreadcrumb(menuEntries, currentMenuEntry, crumbs)

  return crumbs
})

const fillBreadcrumb = (menuEntries, menuItem, crumbs) => {
  if (menuItem) {
    const crumb = {
      label: i18n.t(menuItem.code, 2),
      to: menuItem.route,
    }
    if (menuItem.icon) {
      crumb.icon = menuItem.icon
    }
    crumb.color = props.color
    crumbs.unshift(crumb)
    if (menuItem.parent_id) {
      const parentItem = menuEntries.find(item => item.id === menuItem.parent_id)
      fillBreadcrumb(menuEntries, parentItem, crumbs)
    }
  }
}

const breadCrumbClass = computed(() => {
  return `text-${props.color}`
})

</script>
<template>
  <q-breadcrumbs
    separator="mdi-chevron-right"
    :class="breadCrumbClass"
    class="text-primary"
  >
    <template #separator>
      <q-icon
        size="1rem"
        name="mdi-chevron-right"
        :color="props.color"
      />
    </template>
    <q-breadcrumbs-el
      v-for="(crumb, index) in breadcrumbEntries"
      :key="index"
      :label="crumb.label"
      :to="crumb.to"
      :icon="crumb.icon"
    />
  </q-breadcrumbs>
</template>
