<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { DxDataGrid } from 'devextreme-vue/data-grid'
import { DxTagBox } from 'devextreme-vue/tag-box'
import { useGridRef, useODataSource, useLookup, useDefaultExport, useDefaultEditing, useDefaultGridOptions, useDefaultDialogTitle, useCurrencyColumnFormatter, useCurrencyOptions } from '@/composables/data-grid'
import { useCurrencyColumn } from '@/composables/data-grid-column'

import { planningApi } from '@/apis/planning.api'
import { usePortalStore } from '@/stores/portal.store'
import { usePlanningStore } from '@/stores/planning.store'

const gridId = 'equipments-grid'

const i18n = useI18n()
const t = i18n.t
const portalStore = usePortalStore()
const planningStore = usePlanningStore()
const localizedTags = portalStore.getLocalizedTags().filter(i => i.type === planningStore.serviceTypeEnum.EQUIPMENT)
const localizedServiceStatuses = portalStore.getLocalizedServiceStatuses()
const localizedServiceAvailabilityTypes = portalStore.getLocalizedServiceAvailabilityTypes()
const printQrCodeToolbarButton = ref(null)
const selectedRows = ref([])
const editors = {}

const { gridRef } = useGridRef()
const { defaultDialogTitle } = useDefaultDialogTitle()

const gridDataSource = useODataSource(planningApi.getAbsoluteUrl() + planningApi.equipmentsUrl)
const fetchRentalCompanies = async() => (await planningStore.fetchCompaniesAsync({}, true))?.filter(i => i.is_rental)
let rentalCompanies = await fetchRentalCompanies()

const customizeCell = (gridCell, exportCell, isPdf) => {
  if (gridCell.rowType === 'data' && gridCell.column.dataField === 'tags') {
    const tagNames = gridCell.data.tags.map(tag => localizedTags.find(i => i.id === tag).name)
    const value = tagNames.join(', ')
    isPdf ? exportCell.text = value : exportCell.value = value
  }
}

const { exportOptions, onExporting } = useDefaultExport(
  {
    fileName: i18n.t('common.equipment', 2),
    isLandScape: true,
    customizeCellExcel: ({gridCell, excelCell}) => customizeCell(gridCell, excelCell, false),
    customizeCellPdf: ({gridCell, pdfCell}) => customizeCell(gridCell, pdfCell, true),
  })

const columns = [
  { dataField: 'name', caption: i18n.t('common.name'), validationRules: [{ type: 'required' }], sortOrder: 'asc'},
  { dataField: 'description', caption: i18n.t('common.description') },
  {
    dataField: 'tags',
    caption: i18n.t('common.tag', 2),
    lookup: useLookup({
      dataSource: localizedTags,
      valueExpr: 'id',
      displayExpr: 'name',
    }),
    setCellValue: (rowData, value) => (rowData.tags = value),
    cellTemplate: 'tag-cell-template',
    editCellTemplate: 'tag-edit-cell-template',
    validationRules: [{ type: 'required' }]
  },
  {
    dataField: 'availability_type',
    caption: i18n.t('common.availabilityType'),
    lookup: useLookup({
      dataSource: localizedServiceAvailabilityTypes,
      valueExpr: 'id',
      displayExpr: 'name',
    }),
    cellTemplate: 'availability-type-cell-template',
    validationRules: [{ type: 'required' }]
  },
  {
    dataField: 'condition_value',
    caption: i18n.t('common.condition'),
    lookup: useLookup({
      dataSource: planningStore.getLocalizedEquipmentConditions(),
      valueExpr: 'id',
      displayExpr: 'name',
    }),
    cellTemplate: 'condition-cell-template',
  },
  {
    dataField: 'condition_comment',
    caption: i18n.t('common.conditionComment'),
  },
  {
    dataField: 'status',
    caption: i18n.t('common.status'),
    lookup: useLookup({
      dataSource: localizedServiceStatuses,
      valueExpr: 'id',
      displayExpr: 'name',
    }),
    cellTemplate: 'status-cell-template',
    validationRules: [{ type: 'required' }]
  },
  {
    dataField: 'is_rented',
    dataType: 'boolean',
    caption: i18n.t('common.rented'),
    cellTemplate: 'rented-cell-template',
  },
  {
    dataField: 'buy_price',
    dataType: 'currency',
    caption: i18n.t('common.price'),
    calculateCellValue: (rowData) => useCurrencyColumnFormatter(rowData, 'buy_price', rowData.buy_price_currency),
    validationRules: [],
  },
  useCurrencyColumn({
    dataField: 'buy_price_currency',
    referenceField: 'buy_price',
  }),
  {
    dataField: 'buy_date',
    caption: i18n.t('common.buyDate'),
    dataType: 'date',
  },
  { dataField: 'need_qr_scan', caption: i18n.t('common.needQrScan'), dataType: 'boolean' },
  { dataField: 'uuid', caption: i18n.t('common.uuid'), visible: false, formItem: { visible: false } },
  { dataField: 'rental_company_name', caption: i18n.t('common.rentalCompany.name') },
  {
    dataField: 'rental_company.id',
    lookup: useLookup({
      dataSource: () => rentalCompanies,
      valueExpr: 'id',
      displayExpr: 'name',
    }, {
      showClearButton: true,
      acceptCustomValue: true,

    }),
    setCellValue: (rowData, value, currentData) => {
      const currentCompany = rentalCompanies.find(i => i.id === value)
      if (currentCompany) {
        rowData.rental_company = {
          id: currentCompany?.id,
          name: currentCompany?.name,
          contact: currentCompany?.contact,
        }
      } else if (value === null) {
        rowData.rental_company = null
      }
    },
    caption: i18n.t('common.rentalCompany.name')
  },
  { dataField: 'rental_company.contact', caption: i18n.t('common.rentalCompany.contact') },
]

const editing = useDefaultEditing(i => defaultDialogTitle(i, i18n.t('common.equipment')), {
  colCount: 4,
  items: [
    {
      itemType: 'group',
      caption: i18n.t('common.mainInfo'),
      colCount: 4,
      colSpan: 3,
      items: [
        { dataField: 'name' },
        { dataField: 'description' },
        { dataField: 'tags', colSpan: 2 },
        { dataField: 'availability_type' },
        { dataField: 'status' },
      ]
    },
    {
      itemType: 'group',
      caption: i18n.t('common.rentalInfo'),
      colCount: 2,
      items: [
        { dataField: 'rental_company.id', colSpan: 2, editorOptions: { showClearButton: true, acceptCustomValue: true, onCustomItemCreating: (args) => {
          const newCompany = {
            id: -1,
            name: args.text,
            contact: null,
            is_rental: true,
          }
          rentalCompanies.push(newCompany)
          return newCompany
        } } },
        { dataField: 'rental_company.contact', colSpan: 2 },
      ]
    },
    {
      itemType: 'group',
      caption: i18n.t('common.additionalInfo'),
      colCount: 4,
      colSpan: 3,
      items: [
        { dataField: 'buy_price', editorOptions: useCurrencyOptions() },
        { dataField: 'buy_price_currency'},
        { dataField: 'buy_date' },
        { dataField: 'need_qr_scan' },
      ]
    },
    {
      itemType: 'group',
      caption: i18n.t('common.conditionInfo'),
      colCount: 2,
      items: [
        { dataField: 'condition_value', colSpan: 2 },
        { dataField: 'condition_comment', colSpan: 2, editor_type: 'dxTextArea' },
      ]
    },
  ],
})

const onRowInserting = async(e) => (e.data.type = planningStore.serviceTypeEnum.EQUIPMENT)

const onRowUpdating = async(e) => {
  if (e.newData.rental_company?.id < 0) {
    e.newData.rental_company.id = null
    e.newData.rental_company.is_rental = true
    e.newData.rental_company.is_client = false
  }
}

const onSaved = async(e) => {
  rentalCompanies = await fetchRentalCompanies()
  gridRef.value.instance.refresh()
}

const onSelectionChanged = (e) => {
  selectedRows.value = e.selectedRowsData
  printQrCodeToolbarButton.value.option('disabled', e.selectedRowsData.length === 0)
}

const onPrintQrCodeClick = async() => {
  const data = selectedRows.value.map(row => row.id)
  await planningApi.downloadEquipmentQrCodesAsync(`${i18n.t('common.equipment', 2)}.pdf`, data)
  selectedRows.value = []
  gridRef.value.instance.clearSelection()
  await gridRef.value.instance.refresh()
}

const onToolbarPreparing = (e) => {
  e.toolbarOptions.items.unshift(
    {
      widget: 'dxButton',
      location: 'after',
      options: {
        icon: 'mdi mdi-qrcode',
        disabled: true,
        text: i18n.t('common.printQrCode'),
        onClick: onPrintQrCodeClick,
        onInitialized: (e) => { printQrCodeToolbarButton.value = e.component }
      }
    },
  )
}

const onEditorPreparing = (e) => {
  if (e.parentType === 'dataRow' && e.dataField === 'buy_price') {
    e.editorOptions.onInitialized = (e) => { editors['buy_price'] = e.component }
    // const currency = webSiteStore.currencies.find((currency) => currency.id === e.row.data?.buy_price_currency)
    // if (currency) {
    //   e.editorOptions.format = useCurrencyOptions({ currency: currency.iso_code }).format
    // }
  }
}

const gridOptions = useDefaultGridOptions({
  id: gridId,
  columns: columns,
  dataSource: gridDataSource,
  editing: editing,
  export: exportOptions,
  onEditorPreparing: onEditorPreparing,
  onExporting: onExporting,
  onRowInserting: onRowInserting,
  onRowUpdating: onRowUpdating,
  onSaved: onSaved,
  onSelectionChanged: onSelectionChanged,
  onToolbarPreparing: onToolbarPreparing,
  selection: { mode: 'multiple', showCheckBoxesMode: 'onClick', selectAllMode: 'page' },
  clearFilterButton: true,
})

</script>
<template>
  <div>
    <dx-data-grid
      ref="gridRef"
      v-bind="gridOptions"
    >
      <template #tag-cell-template="{ data: templateOptions }">
        <q-chip
          v-for="tag in templateOptions.data.tags"
          :key="tag"
          size="sm"
          :label="localizedTags.find(t => t.id === tag).name"
        />
      </template>
      <template #tag-edit-cell-template="{ data: templateOptions }">
        <dx-tag-box
          :value="templateOptions.data.tags"
          :data-source="localizedTags"
          value-expr="id"
          display-expr="name"
          @value-changed="(e) => templateOptions.setValue(e.value)"
        />
      </template>
      <template #status-cell-template="{ data: templateOptions }">
        <q-chip
          size="sm"
          :label="localizedServiceStatuses.find(s => s.id === templateOptions.data.status).name"
          :color="templateOptions.data.status === 0 ? 'green' : templateOptions.data.status === 1 ? 'orange' : 'yellow'"
        />
      </template>
      <template #rented-cell-template="{ data: templateOptions }">
        <q-chip
          v-if="templateOptions.data.is_rented"
          size="sm"
          :label="t('common.rented')"
          color="teal"
        />
      </template>
      <template #availability-type-cell-template="{ data: templateOptions }">
        <q-chip
          size="sm"
          :label="localizedServiceAvailabilityTypes.find(s => s.id === templateOptions.data.availability_type).name"
          :color="templateOptions.data.availability_type === 0 ? 'green' : templateOptions.data.availability_type === 1 ? 'green' : 'yellow'"
        />
      </template>
      <template #condition-cell-template="{ data: templateOptions }">
        <q-chip
          v-if="templateOptions.data.condition_value"
          size="sm"
          :label="planningStore.getConditionLabel(templateOptions.data.condition_value)"
          :color="planningStore.getConditionColor(templateOptions.data.condition_value)"
        />
      </template>
    </dx-data-grid>
  </div>
</template>
