<script setup>
import { ref } from 'vue'

import ProjectListItem from '@/components/shared/ProjectListItem.vue'

import { useScheduleStore } from '@/stores/schedule.store'
import { useWebSiteStore } from '@/stores/web-site.store'

const webSiteStore = useWebSiteStore()
const scheduleStore = useScheduleStore()

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits(['update'])
const selectedAppointment = ref(props.modelValue)
const project = ref(
  {
    ...selectedAppointment.value.project,
    services: [selectedAppointment.value.staff.id],
  }
)

const onDeleteButtonClick = async() => {
  await scheduleStore.deleteEventAsync(selectedAppointment.value.id)
  emit('update')
}

</script>
<template>
  <q-card>
    <q-card-section>
      <project-list-item
        hide-color
        show-staff
        :clickable="false"
        :item="project"
        :deletable="selectedAppointment.service_id === webSiteStore.user.service_id"
        @delete="onDeleteButtonClick"
      />
    </q-card-section>
  </q-card>
</template>
