<script setup>
import { computed } from 'vue'
import i18n from '@/plugins/i18n'

const props = defineProps({
  continue: {
    type: Function,
    required: false,
  },
  back: {
    type: Function,
    required: false,
  },
  finish: {
    type: Function,
    required: false,
  },
  color: {
    type: String,
    required: false,
    default: 'primary',
  },
  icon: {
    type: String,
    required: false,
  },
  iconRight: {
    type: String,
    required: false,
  },
  finishLabel: {
    type: String,
    required: false,
    default: 'Finish',
  },
  disable: {
    type: Boolean,
    required: false,
  },
})

const disable = computed(() => { return props.disable })
const t = i18n.global.t

</script>
<template>
  <q-stepper-navigation align-right>
    <q-btn
      v-if="props.back"
      :label="t('common.back')"
      class="q-mr-sm"
      @click="props.back()"
    />
    <q-btn
      v-if="props.continue"
      :color="props.color"
      :icon="props.icon"
      :icon-right="props.iconRight"
      :label="t('common.continue')"
      :disable="disable"
      class="q-mr-sm"
      @click="props.continue()"
    />
    <q-btn
      v-if="props.finish"
      :color="props.color"
      :icon="props.icon"
      :icon-right="props.iconRight"
      :label="props.finishLabel"
      :disable="disable"
      class="q-ml-sm"
      @click="props.finish()"
    />
  </q-stepper-navigation>
</template>
