<script setup>
import ProjectsView from '@/components/planner/ProjectsView.vue'
import ProjectsGrid from '@/components/planner/ProjectsGrid.vue'

import { useDetectDevice } from '@/composables/device'

const { isMobile } = useDetectDevice()
</script>
<template>
  <projects-view v-if="isMobile" />
  <projects-grid v-else />
</template>
