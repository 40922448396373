import script from "./TimeSelector.vue?vue&type=script&setup=true&lang=js"
export * from "./TimeSelector.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QTime from 'quasar/src/components/time/QTime.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/.pnpm/vue-cli-plugin-quasar@5.0.2/node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QIcon,QPopupProxy,QTime,QBtn});qInstall(script, 'directives', {ClosePopup});
