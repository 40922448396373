import { onMounted, onBeforeUnmount } from 'vue'

import { useDetectDevice } from './device'

export const useNavigator = (onPopStateCallback) => {
  const { isMobile } = useDetectDevice()
  if (isMobile) {
    let popStateEventListener = null
    onMounted(() => {
      window.history.pushState(null, '', window.location.href)
      popStateEventListener = window.addEventListener('popstate', () => {
        onPopStateCallback()
      })
    })
    onBeforeUnmount(() => { window.removeEventListener('popstate', popStateEventListener) })
  }
}
