
import { ref } from 'vue'

export const useStepper = (config) => {
  const step = ref(config?.step || 1)

  const nextStep = () => { step.value++ }
  const prevStep = () => { step.value-- }

  return {step, nextStep, prevStep}
}
