
import { ref, computed } from 'vue'

export const useOrientation = () => {
  const orientation = ref(screen.orientation.type)
  const isLandscape = computed(() => orientation.value.includes('landscape'))

  screen.orientation.onchange = () => {
    orientation.value = screen.orientation.type
  }

  return { orientation, isLandscape }
}

export const useTouch = () => {
  const isTouchDevice = 'ontouchstart' in window || navigator.msMaxTouchPoints > 0
  return { isTouchDevice }
}

export const useDetectDevice = () => {
  const isMobile = ref(false)
  const isDesktop = ref(false)

  const detectDevice = () => {
    const { userAgent } = navigator
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)
    const desktop = !mobile

    isMobile.value = mobile
    isDesktop.value = desktop
  }

  detectDevice()

  return { isMobile, isDesktop }
}

