<script setup>

import i18n from '@/plugins/i18n'
import dayjs from 'dayjs'

import { ref, onMounted } from 'vue'
import { router } from '@/router'

import { date } from 'quasar'
import { formatDate, formatTime } from '@/lib/utils'
import { DxList } from 'devextreme-vue/list'

import { useODataSource } from '@/composables/data-grid'
import { useDetectDevice } from '@/composables/device'
import { scheduleApi } from '@/apis/schedule.api'
import { useWebSiteStore } from '@/stores/web-site.store'
import { usePlanningStore } from '@/stores/planning.store'
import { usePortalStore } from '@/stores/portal.store'
import { useScheduleStore } from '@/stores/schedule.store'

import ProjectListItem from '@/components/shared/ProjectListItem.vue'
import EventProjectStaffEquipmentToggle from '@/components/shared/EventProjectStaffEquipmentToggle.vue'
import EventWarningToggle from '@/components/shared/EventWarningToggle.vue'
import EventVacationToggle from '@/components/shared/EventVacationToggle.vue'
import ProjectCheckOutIn from '@/components/shared/ProjectCheckOutIn.vue'

const t = i18n.global.t
const { isMobile } = useDetectDevice()
const dataSource = ref([])
const planningStore = usePlanningStore()
const webSiteStore = useWebSiteStore()
const portalStore = usePortalStore()
const scheduleStore = useScheduleStore()

const editAppointment = ref(null)
const editAppointmentDialog = ref(false)

const currentView = ref(webSiteStore.schedulerViews.PROJECTS)
const projectsDataSource = ref([])
const staffDataSource = ref([])
const equipmentDataSource = ref([])
const selectedProjectIds = ref([])
const selectedStaffIds = ref([])
const selectedEquipmentIds = ref([])
const selectedProject = ref(null)
const vacations = ref([])
const warnings = ref([])

const updateDataSourceAsync = async() => {
  dataSource.value = useODataSource(scheduleApi.getAbsoluteUrl() + scheduleApi.eventsUrl, {
    customParamsFn: () => {
      const params = {
        types: [scheduleStore.eventTypeEnum.PROJECT],
        group_by_day: true,
      }

      if (webSiteStore.showEventWarnings) {
        params.show_warnings = true
      }
      if (webSiteStore.showEventVacations) {
        params.show_vacations = true
      }

      if (currentView.value === webSiteStore.schedulerViews.PROJECTS) {
        params.service_type = portalStore.serviceTypeEnum.PROJECT
        params.project_ids = selectedProjectIds.value
      }
      if (currentView.value === webSiteStore.schedulerViews.STAFF) {
        params.service_type = portalStore.serviceTypeEnum.STAFF
        params.staff_ids = selectedStaffIds.value
      }
      if (currentView.value === webSiteStore.schedulerViews.EQUIPMENT) {
        params.service_type = portalStore.serviceTypeEnum.EQUIPMENT
        params.equipment_ids = selectedEquipmentIds.value
      }
      return params
    },
    postProcess: (data) => {
      warnings.value = []
      return data.map(i => {
        if (webSiteStore.showEventWarnings) {
          i.warnings?.forEach(w => {
            if (!warnings.value.map(i => i.id).includes(w.id)) {
              warnings.value.push(w)
            }
          })
        }
        return i
      })
    },
  })
}

const mounted = ref(false)

onMounted(async() => {
  await Promise.all([
    planningStore.fetchProjectsAsync({}, true),
    planningStore.fetchStaffsAsync({}, true),
    planningStore.fetchEquipmentsAsync({}, true),
  ])
  projectsDataSource.value = planningStore.projects
  selectedProjectIds.value = projectsDataSource.value.map(i => i.id)

  staffDataSource.value = planningStore.staff
  selectedStaffIds.value = staffDataSource.value.map(i => i.id)

  equipmentDataSource.value = planningStore.equipments
  selectedEquipmentIds.value = equipmentDataSource.value.map(i => i.id)
  await updateDataSourceAsync()
  mounted.value = true
})

const getProjectById = (id, serviceIds) => id === 0 ? planningStore.getVacationProject(t, serviceIds) : planningStore.getProjectById(id)

webSiteStore.fabActions = [
  {
    id: 'add',
    icon: 'mdi-calendar-plus',
    onClick: () => {
      editAppointment.value = {
        dateRange: {
          from: formatDate(),
          to: formatDate(),
        },
        startTime: formatTime(),
        endTime: formatTime(dayjs().add(1, 'hour')),
      }
      editAppointmentDialog.value = true
    },
  },
]

const onServiceViewChange = async(value) => {
  currentView.value = value
  await updateDataSourceAsync(value)
}

const onServiceSelectionChange = async(value) => {
  if (currentView.value === webSiteStore.schedulerViews.PROJECTS) {
    selectedProjectIds.value = value
  } else if (currentView.value === webSiteStore.schedulerViews.STAFF) {
    selectedStaffIds.value = value
  } else if (currentView.value === webSiteStore.schedulerViews.EQUIPMENT) {
    selectedEquipmentIds.value = value
  }
  await updateDataSourceAsync()
}

const getEventWarningName = (value) => Object.keys(planningStore.eventWarningTypeEnum).find(i => planningStore.eventWarningTypeEnum[i] === value)?.toLocaleLowerCase()

const onAppointmentClick = (projectId, day) => {
  if (projectId === 0) {
    webSiteStore.eventSchedulerDate = day.start_date
    return router.push({ name: 'events-scheduler' })
  }
  selectedProject.value = planningStore.getProjectById(projectId)
}

const onChange = async() => {
  selectedProject.value = null
  await updateDataSourceAsync()
}

const onProjectCheckOutInChange = async() => {
  await planningStore.fetchProjectsAsync({}, true)
  await onChange()
}

</script>
<template>
  <div>
    <q-dialog
      :model-value="selectedProject !== null"
      :full-width="isMobile"
      @hide="selectedProject = null"
    >
      <project-check-out-in
        v-if="selectedProject.id !== 0"
        :model-value="selectedProject"
        @update="onProjectCheckOutInChange"
      />
    </q-dialog>
    <div style="display: flex;">
      <event-project-staff-equipment-toggle
        v-if="mounted"
        :view="currentView"
        :projects-data-source="projectsDataSource"
        :staff-data-source="staffDataSource"
        :equipment-data-source="equipmentDataSource"
        @view-change="onServiceViewChange"
        @selection-change="onServiceSelectionChange"
      />
      <q-space />
      <event-vacation-toggle
        v-model="webSiteStore.showEventVacations"
        :vacations="vacations"
        @update:model-value="updateDataSourceAsync"
      />
      <event-warning-toggle
        v-model="webSiteStore.showEventWarnings"
        :warnings="warnings"
        @update:model-value="updateDataSourceAsync"
      />
    </div>
    <DxList
      :data-source="dataSource"
      :select-by-click="false"
      height="85vh"
      page-load-mode="scrollBottom"
      class="events-list"
      item-template="item"
      selection-mode="none"
      :active-state-enabled="false"
    >
      <template #item="{ data: day }">
        <q-separator />
        <q-item>
          <q-item-section
            avatar
            class="q-px-none"
            style="justify-content: flex-start;"
          >
            <span class="text-weight-thin q-pt-sm">
              {{ date.formatDate(day.start_date, 'DD MMM') }}
            </span>
          </q-item-section>
          <q-item-section>
            <q-item
              v-for="warning in day.warnings"
              :key="warning"
              class="q-px-none"
            >
              <q-item-section
                v-ripple
                class="warning-item"
                @click="onAppointmentClick(0, day)"
              >
                <q-icon
                  name="mdi-alert"
                  color="warning"
                  size="sm"
                  class="q-pr-sm"
                />
                <q-item-label>
                  {{ t(`warning.event.${getEventWarningName(warning.type)}`) }}
                </q-item-label>
              </q-item-section>
            </q-item>
            <q-separator v-if="day.warnings" />
            <div
              v-for="project in day.projects"
              :key="project"
              style="width: 100%;"
            >
              <project-list-item
                v-ripple
                hide-date
                hide-journalist
                hide-location
                :hide-time="project === 0"
                :show-staff="currentView !== webSiteStore.schedulerViews.EQUIPMENT"
                :show-equipment="currentView === webSiteStore.schedulerViews.EQUIPMENT"
                :item="getProjectById(project, day.services)"
                @click="onAppointmentClick(project, day)"
              />
            </div>
          </q-item-section>
        </q-item>
      </template>
    </DxList>
  </div>
</template>

<style lang="scss">
.events-list {
  & .dx-item-content.dx-list-item-content {
    padding: 0 !important;
  }
}
.warning-item {
  flex-direction: row; align-items: center; justify-content: start; padding-left:4px
}
</style>
