import dayjs from 'dayjs'

export const isNotEmpty = (value) => value !== undefined && value !== null && value !== ''

export const stringToColor = (str) => {
  if (!str) return '#000'
  let hash = 0
  let i
  let chr
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i)
    hash = ((hash << 5) - hash) + chr
    hash |= 0
  }
  return `hsl(${hash % 360}, 70%, 60%)`
}

export const getInitials = (name) => {
  if (!name) { return '' }
  return name.split(' ').map(n => n[0]).join('').toUpperCase()
}

export const formatDate = (date = new Date()) => {
  if (date) {
    return dayjs(date).format('YYYY/MM/DD')
  }
  return ''
}

export const formatTime = (date = new Date()) => {
  if (date) {
    return dayjs(date).format('HH:mm')
  }
  return ''
}

export const formatString = (value, format) => {
  if (!value) return ''
  for (const char of value) {
    format = format.replace('#', char)
  }
  return format
}

export const stringFormats = {
  PHONE: '+## (###) ###-####',
}

// eslint-disable-next-line no-extend-native
Array.prototype.sortBy = function(fn) {
  return this.sort((a, b) => {
    return fn(a)?.localeCompare(fn(b))
  })
}
