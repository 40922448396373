<script setup>
import dayjs from 'dayjs'

import { useI18n } from 'vue-i18n'
import { DxDataGrid } from 'devextreme-vue/data-grid'
import { useGridRef, useODataSource, useLookup, useDefaultExport, useDefaultEditing, useDefaultGridOptions, useDefaultDialogTitle } from '@/composables/data-grid'

import { planningApi } from '@/apis/planning.api'
import { usePlanningStore } from '@/stores/planning.store'
import { useWebSiteStore } from '@/stores/web-site.store'

import FileUploader from '@/components/shared/FileUploader.vue'

import ProjectListingItemsGrid from './ProjectListingItemsGrid.vue'

const gridId = 'projects-grid'

const i18n = useI18n()
const planningStore = usePlanningStore()
const webSiteStore = useWebSiteStore()

const { gridRef } = useGridRef()
const editors = {}
const { defaultDialogTitle } = useDefaultDialogTitle()

const gridDataSource = useODataSource(planningApi.getAbsoluteUrl() + planningApi.projectsUrl)

const { exportOptions, onExporting } = useDefaultExport({ fileName: i18n.t('common.project', 2)})

const columns = [
  {
    dataField: 'start', caption: i18n.t('common.start'),
    dataType: 'datetime', sortOrder: 'desc',
    editorOptions: { type: 'datetime' },
    validationRules: [{ type: 'required' }]
  },
  {
    dataField: 'end',
    caption: i18n.t('common.end'),
    dataType: 'datetime',
    validationRules: [{ type: 'required' }]
  },
  {
    dataField: 'name',
    caption: i18n.t('common.name'),
    validationRules: [{ type: 'required' }]
  },
  { dataField: 'description', caption: i18n.t('common.description') },
  {
    dataField: 'listing', caption: i18n.t('common.listing'),
    lookup: useLookup({
      dataSource: {
        store: planningStore.listings,
      },
      displayExpr: 'name',
    }),
    validationRules: [{ type: 'required' }]
  },
  { dataField: 'journalist', caption: i18n.t('common.journalist') },
  { dataField: 'location', caption: i18n.t('common.location') },
  { dataField: 'project_files', caption: i18n.t('common.attachment', 2), editCellTemplate: 'fileUploader', visible: false, showInColumnChooser: false },
  {
    dataField: 'status', caption: i18n.t('common.status'),
    lookup: useLookup({store: planningStore.getLocalizedProjectStatuses(), displayExpr: 'name' }),
    cellTemplate: 'status-cell-template',
  },
]

const editing = useDefaultEditing(i => defaultDialogTitle(i, i18n.t('common.project')), {
  colCount: 2,
  items: [
    {
      itemType: 'group',
      caption: i18n.t('common.mainInfo'),
      colCount: 3,
      items: [
        { dataField: 'name' },
        { dataField: 'description', colSpan: 2 },
        { dataField: 'start', editorType: 'dxDateBox', editorOptions: { type: 'datetime' } },
        { dataField: 'end', editorType: 'dxDateBox', editorOptions: { type: 'datetime' } },
        { dataField: 'status' },
      ]
    },
    {
      itemType: 'group',
      caption: i18n.t('common.additionalInfo'),
      colCount: 3,
      items: [
        { dataField: 'listing' },
        { dataField: 'journalist' },
        { dataField: 'location' },
        { dataField: 'project_files', colSpan: 3 },
      ]
    }
  ],
})

const onInitNewRow = (e) => {
  e.data.status = 0
  const defaultStartHourAndMinute = webSiteStore.getDefaultSettings()?.project?.startHour?.split(':')
  if (defaultStartHourAndMinute) {
    e.data.start = dayjs().set('hour', defaultStartHourAndMinute?.[0]).set('minute', defaultStartHourAndMinute?.[1]).toISOString()
  }
  const defaultEndHourAndMinute = webSiteStore.getDefaultSettings()?.project?.endHour?.split(':')
  if (defaultEndHourAndMinute) {
    e.data.end = dayjs().set('hour', defaultEndHourAndMinute?.[0]).set('minute', defaultEndHourAndMinute?.[1]).toISOString()
  }
}

const alwaysEditableColumns = ['name', 'description', 'status', 'journalist', 'location', 'project_files']

const onEditorPreparing = async(e) => {
  if (e.parentType === 'dataRow') {
    const fieldName = e.dataField
    e.editorOptions.onInitialized = (e) => { editors[fieldName] = e.component }
  }

  if (e.parentType === 'dataRow' && e.dataField === 'start') {
    const defaultOnValueChanged = e.editorOptions.onValueChanged
    e.editorOptions.onValueChanged = (e) => {
      defaultOnValueChanged(e)
      if (editors['end']) {
        editors['end'].option('min', e.value?.toISOString())
        editors['end'].option('value', e.value?.toISOString())
      }
    }
  }

  if (e.row?.data?.id) {
    e.promise = planningStore.fetchProjectByIdAsync(e.row.data?.id).then((project) => {
      for (const editor in editors) {
        if (project.data?.has_listing_items && !alwaysEditableColumns.includes(editor)) {
          editors[editor].option('readOnly', true)
        }
      }
    })
  }
}

const gridOptions = useDefaultGridOptions({
  id: gridId,
  dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ss.SSSZ',
  dataSource: gridDataSource,
  columns: columns,
  editing: editing,
  export: exportOptions,
  masterDetail: {
    enabled: true,
    template: 'master-detail',
  },
  onInitNewRow: onInitNewRow,
  onExporting: onExporting,
  onEditorPreparing: onEditorPreparing,
  clearFilterButton: true,
})

const onDownloadProjectFileAsync = async(e) => {
  if (e.value) {
    await planningStore.downloadProjectFileAsync(e.value.id, e.value.name)
  }
}

</script>
<template>
  <dx-data-grid
    ref="gridRef"
    v-bind="gridOptions"
  >
    <template #master-detail="{ data: project }">
      <project-listing-items-grid
        :data="project"
      />
    </template>
    <template #status-cell-template="{ data: templateOptions }">
      <q-chip
        size="sm"
        :label="planningStore.getLocalizedProjectStatuses()[templateOptions.data.status].name"
        :color="planningStore.getProjectStatusColor(templateOptions.data.status)"
      />
    </template>
    <template #fileUploader="{ data: cellInfo }">
      <file-uploader
        :cell-info="cellInfo"
        @download="onDownloadProjectFileAsync"
      />
    </template>
  </dx-data-grid>
</template>
