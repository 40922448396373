<script setup>

import { ref } from 'vue'
import dayjs from 'dayjs'

const props = defineProps({
  modelValue: {
    type: [Object, String, null],
    required: true,
  },
  availableDates: {
    type: [Array, Function, null],
    required: false,
  },
  events: {
    type: [Array, Function, null],
    required: false,
  },
  eventColor: {
    type: [String, Function, null],
    required: false,
  },
  label: {
    type: String,
    required: false,
  },
  end: {
    type: Boolean,
    required: false,
  },
  closeButton: {
    type: Boolean,
    required: false,
  },
})

const emit = defineEmits(['update:modelValue'])

const date = props.end ? ref(dayjs(props.modelValue.end).format('YYYY/MM/DD'))
  : ref({
    from: dayjs(props.modelValue.start).format('YYYY/MM/DD'),
    to: dayjs(props.modelValue.end).format('YYYY/MM/DD'),
  })
const originalStartDate = dayjs(props.modelValue.start)
const originalEndDate = dayjs(props.modelValue.end)

const onDateUpdate = (val) => {
  date.value = val

  if (val) {
    let startDate = dayjs(val?.from || val)
    let endDate = dayjs(val?.to || val)

    if (originalStartDate.isValid()) {
      startDate = startDate.set('hour', originalStartDate.hour())
      startDate = startDate.set('minute', originalStartDate.minute())
    }

    if (originalEndDate.isValid()) {
      endDate = endDate.set('hour', originalEndDate.hour())
      endDate = endDate.set('minute', originalEndDate.minute())
    }

    emit('update:modelValue', {
      ...props.modelValue,
      start: startDate.toISOString(),
      end: endDate.toISOString()
    })
  } else {
    emit('update:modelValue', {
      ...props.modelValue,
      start: null,
      end: null
    })
  }
}

</script>
<template>
  <q-date
    :model-value="date"
    first-day-of-week="1"
    range
    today-btn
    :options="props.availableDates"
    :events="props.events"
    :event-color="props.eventColor"
    @update:model-value="onDateUpdate"
  >
    <div
      v-if="closeButton"
      class="row items-center justify-end"
    >
      <q-btn
        v-close-popup
        label="Close"
        color="primary"
        flat
      />
    </div>
  </q-date>
</template>
