<script setup>
import { initPortalStoreAsync } from '@/stores/portal.store'
import { useWebSiteStore } from '@/stores/web-site.store'
import { ref } from 'vue'

await initPortalStoreAsync()
const webSiteStore = useWebSiteStore()

// const title = ref('Reel-Sync')
const username = ref('')
const password = ref('')
const isLoggingIn = ref(false)
const passwordFieldType = ref('password')
const visibilityIcon = ref('mdi-eye')

const loginAsync = async() => {
  isLoggingIn.value = true
  try {
    await webSiteStore.loginAsync(username.value, password.value)
  } catch (error) { }
  isLoggingIn.value = false
}

const switchVisibility = () => {
  passwordFieldType.value = passwordFieldType.value === 'password' ? 'text' : 'password'
  visibilityIcon.value = visibilityIcon.value === 'mdi-eye' ? 'mdi-eye-off' : 'mdi-eye'
}

const isLoginButtonEnabled = () => {
  return username.value && password.value && !isLoggingIn.value && username.value.trim() && password.value.trim()
}

</script>

<template>
  <q-layout
    id="login-layout"
    view="hHh Lpr lFf"
  >
    <q-page-container>
      <q-page
        class="window-height row justify-center items-center page-background"
      >
        <div class="column text-white justify-center ">
          <div class="text-white row justify-center text-h4">
            Welcome
          </div>
          <transition
            appear
            name="slide-fade"
          >
            <div>
              <div class="row justify-center q-pt-xl">
                <q-avatar
                  size="96px"
                  class="justify-center"
                >
                  <img src="https://colorlib.com/etc/lf/Login_v6/images/avatar-01.jpg">
                </q-avatar>
              </div>
              <div class="row justify-center q-pt-xl">
                <q-form
                  autocomplete="off"
                  class="q-px-xl"
                  @keydown.enter="loginAsync"
                >
                  <q-input
                    v-model="username"
                    for="username"
                    dark
                    color="white"
                    type="username"
                    class="q-mb-lg"
                    lazy-rules
                    :label="$t('common.username')"
                    :rules="[val => !!val && !!val.trim() || $t('common.required')]"
                  >
                    <template #prepend>
                      <q-icon name="mdi-account" />
                    </template>
                  </q-input>
                  <q-input
                    v-model="password"
                    for="password"
                    dark
                    color="white"
                    class="q-mb-lg"
                    :type="passwordFieldType"
                    :label="$t('common.password')"
                    lazy-rules
                    :rules="[val => !!val && !!val.trim() || $t('common.required')]"
                  >
                    <template #prepend>
                      <q-icon name="mdi-lock" />
                    </template>
                    <template #append>
                      <q-icon
                        :name="visibilityIcon"
                        class="cursor-pointer"
                        @click="switchVisibility"
                      />
                    </template>
                  </q-input>
                </q-form>
              </div>
              <div class="row justify-center q-pt-xl">
                <!-- <q-card-actions class="q-pa-xl"> -->
                <q-btn
                  v-ripple
                  rounded=""
                  size="lg"
                  color="primary"
                  class="full-width text-white"
                  :disbled="!isLoginButtonEnabled()"
                  :loading="isLoggingIn"
                  :label="$t('common.login')"
                  @click="loginAsync"
                />
                <!-- </q-card-actions> -->
                <!-- </q-card> -->
              </div>
            </div>
          </transition>
        </div>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<style scoped lang="scss">
@import '@/styles/quasar.variables.scss';

.login-container {
  width: 50rem;
  max-width: 100%;
  margin: 0 auto;
}

.slide-fade-enter-active {
  transition: all 1s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s ease-in;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(30px);
  opacity: 0;
}

.page-background {
  background: linear-gradient(lighten($primary, 40%), darken($primary, 30%));
}
</style>
