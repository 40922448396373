import script from "./ProjectEdit.vue?vue&type=script&setup=true&lang=js"
export * from "./ProjectEdit.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QStepper from 'quasar/src/components/stepper/QStepper.js';
import QStep from 'quasar/src/components/stepper/QStep.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QColor from 'quasar/src/components/color/QColor.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QFile from 'quasar/src/components/file/QFile.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../node_modules/.pnpm/vue-cli-plugin-quasar@5.0.2/node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QSpace,QStepper,QStep,QInput,QSelect,QAvatar,QPopupProxy,QColor,QList,QItem,QItemSection,QItemLabel,QIcon,QFile,QBtn});qInstall(script, 'directives', {Ripple});
