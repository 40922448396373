import config from 'devextreme/core/config'
import CustomStore from 'devextreme/data/custom_store'
import DataSource from 'devextreme/data/data_source'
import ODataStore from 'devextreme/data/odata/store'
import '@/styles/dx.material.quasar.css'

import { isNotEmpty } from '@/lib/utils'
import { useWebSiteStore } from '@/stores/web-site.store'

config({
  defaultCurrency: 'EUR',
  editorStylingMode: 'underlined',
  labelMode: 'floating',
})

const gridLoadOptions = ['skip', 'take', 'sort']

export function createODataSource(url) {
  return new DataSource({
    store: new ODataStore({
      url: url,
      beforeSend: (e) => {
        const webSiteStore = useWebSiteStore()
        e.headers = {
          ...e.headers,
          'Authorization': 'Bearer ' + webSiteStore.accessToken,
        }
        if (e.method === 'PUT' || e.method === 'PATCH' || e.method === 'DELETE') {
          const id = e.url.match(/\((\d+)\)/)[1]
          e.url = url + id + '/'
        } else {
          e.url = url
        }
      },
      withCredentials: true,
      key: 'id',
      version: 4,
    }),
  })
}

export const createSimpleDataSource = (array) =>
  new DataSource({
    store: new CustomStore({
      key: 'id',
      byKey: (key) => {
        return array.find((c) => c.id === key)
      },
      load: async(loadOptions) => {
        return {
          data: array,
          totalCount: array.length,
        }
      },
    })
  })

export const createCustomDataSource = (fetchFunc, insertFunc, updateFunc, removeFunc) =>
  new DataSource({
    store: new CustomStore({
      key: 'id',
      load: async(loadOptions) => {
        const params = {}
        gridLoadOptions.forEach(function(i) {
          if (i in loadOptions && isNotEmpty(loadOptions[i])) {
            switch (i) {
              case 'sort':
                params['$orderby'] = loadOptions[i].map((item) => {
                  return item.desc ? '-' + item.selector : item.selector
                }).join(',')
                break
              case 'take':
                params['$top'] = loadOptions[i]
                break
              default:
                params['$' + i] = loadOptions[i]
            }
          }
        })
        return await fetchFunc(params)
          .then(response => {
            return {
              data: response.data.results,
              totalCount: response.data.count,
            }
          })
      },
      insert: async(values) => await insertFunc(values),
      update: async(key, values) => await updateFunc(key, values),
      remove: async(key) => await removeFunc(key),
    }),
  })

