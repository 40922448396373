<script setup>
import { useI18n } from 'vue-i18n'
import { DxDataGrid } from 'devextreme-vue/data-grid'
import { useGridRef, useODataSource, useLookup, useDefaultExport, useDefaultEditing, useDefaultGridOptions, useDefaultDialogTitle } from '@/composables/data-grid'

import { planningApi } from '@/apis/planning.api'
import { usePlanningStore } from '@/stores/planning.store'

import ListingItemsGrid from './ListingItemsGrid.vue'

const gridId = 'listings-grid'

const i18n = useI18n()
const planningStore = usePlanningStore()
const companies = (await planningStore.fetchCompaniesAsync({}, true))?.filter(i => !i.is_rental)

const { gridRef } = useGridRef()
const { defaultDialogTitle } = useDefaultDialogTitle()

const gridDataSource = useODataSource(planningApi.getAbsoluteUrl() + planningApi.listingsUrl)

const { exportOptions, onExporting } = useDefaultExport({ fileName: i18n.t('common.listing', 2)})

const columns = [
  {
    dataField: 'company',
    caption: i18n.t('common.company'),
    lookup: useLookup({
      dataSource: companies,
      valueExpr: 'id',
      displayExpr: 'name',
    }),
    validationRules: [{ type: 'required' }]
  },
  {
    dataField: 'name',
    caption: i18n.t('common.name'),
    validationRules: [{ type: 'required' }],
    sortOrder: 'asc'
  },
  {
    dataField: 'description',
    caption: i18n.t('common.description'),
  },
]

const editing = useDefaultEditing(i => defaultDialogTitle(i, i18n.t('common.listing')), {
  colCount: 1,
  items: [
    {
      itemType: 'group',
      caption: i18n.t('common.mainInfo'),
      colCount: 4,
      items: [
        { dataField: 'company' },
        { dataField: 'name' },
        { dataField: 'description', colSpan: 2 },
      ]
    },
  ],
})

const onRowInserting = async(e) => (e.data.type = planningStore.serviceTypeEnum.STAFF)

const gridOptions = useDefaultGridOptions({
  id: gridId,
  columns: columns,
  dataSource: gridDataSource,
  editing: editing,
  export: exportOptions,
  masterDetail: {
    enabled: true,
    template: 'master-detail',
  },
  onRowInserting: onRowInserting,
  onExporting: onExporting,
  clearFilterButton: true,
})

</script>
<template>
  <dx-data-grid
    ref="gridRef"
    v-bind="gridOptions"
  >
    <template #master-detail="{ data: listing }">
      <listing-items-grid
        :data="listing"
      />
    </template>
  </dx-data-grid>
</template>
