<script setup>
import { ref, computed } from 'vue'
import { useVModel } from '@vueuse/core'
import DxTreeView from 'devextreme-vue/tree-view'
import { useRoute } from 'vue-router'

import i18n from '@/plugins/i18n'
import { useDetectDevice, useOrientation, useTouch } from '@/composables/device'
import { useWebSiteStore } from '@/stores/web-site.store'

import AvatarImage from '@/components/shared/AvatarImage.vue'
import LanguageSelector from '@/components/shared/LanguageSelector.vue'

const t = i18n.global.t
const webSiteStore = useWebSiteStore()
const { isTouchDevice } = useTouch()
const { isLandscape } = useOrientation()
const { isMobile } = useDetectDevice()

const props = defineProps({
  drawer: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['update:drawer'])
const drawerVisible = useVModel(props, 'drawer', emit)

const overlay = ref(webSiteStore.sideBarPinned)
const treeView = ref(null)
const menuEntries = webSiteStore.getMenuEntriesWithRoute()
const isInside = ref(false)
const route = useRoute()

const onMouseEnter = () => {
  if (!isTouchDevice) {
    isInside.value = true
    setTimeout(() => {
      if (isInside.value) {
        overlay.value = false
      }
    }, 500)
  }
}

const onMouseLeave = () => {
  if (!isTouchDevice) {
    isInside.value = false
    setTimeout(() => {
      if (!isInside.value) {
        overlay.value = webSiteStore.sideBarPinned
        if (treeView.value) {
          treeView.value.collapseAll()
        }
      }
    }, 500)
  }
}

const onSideBarPinClick = () => {
  webSiteStore.sideBarPinned = !webSiteStore.sideBarPinned
  overlay.value = webSiteStore.sideBarPinned
}

const saveTreeViewIstance = (e) => {
  treeView.value = e.component
}

const onItemClick = (e) => {
  for (const item of menuEntries) {
    if (item.parent_id === e.itemData.parent_id && item.id !== e.itemData.id) {
      treeView.value.collapseItem(item)
    }
  }
}

const onItemExpanded = (e) => {
  if (e.itemData.parent_id) {
    e.itemElement.classList.remove('dx-state-focused')
    e.itemElement.classList.add('dx-theme-accent-as-text-color')
  }
}

const onItemCollapsed = (e) => {
  e.itemElement.classList.remove('dx-theme-accent-as-text-color')
}

const userName = computed(() => {
  if (webSiteStore.user && webSiteStore.user.firstName) {
    return `${webSiteStore.user.firstName} ${webSiteStore.user.lastName}`
  }
  return webSiteStore.user?.username ?? ''
})

const nodeClass = (item, level = 0) => {
  const classList = selectedMenuEntryIds.value.includes(item.id) ? ['selected'] : ['grayed-out']
  if (item.parent_id) {
    classList.push(...nodeClass(findItemById(item.parent_id), level + 1))
  } else {
    switch (level) {
      case 1: classList.push('q-pl-md'); break
      case 2: classList.push('q-pl-xl'); break
    }
  }
  return classList
}

const findItemById = (id) => {
  for (const item of menuEntries) {
    if (item.id === id) {
      return item
    }
  }
  return null
}

const currentMenuEntry = computed(() => menuEntries.find(item => item.route === route.meta.reference))

const selectedMenuEntryIds = computed(() => {
  let entry = currentMenuEntry.value
  const ids = [entry?.id]
  while (entry?.parent_id) {
    ids.push(entry.parent_id)
    entry = findItemById(entry.parent_id)
  }
  return ids
})

const onLogoutClick = async() => await webSiteStore.logoutAsync()

</script>
<template>
  <div>
    <q-drawer
      v-model="drawerVisible"
      class="nav"
      bordered
      :mini="overlay"
      :width="250"
      :breakpoint="500"
      @mouseenter="onMouseEnter"
      @mouseleave="onMouseLeave"
    >
      <q-list class="nav-tree">
        <q-item
          v-if="drawer && !isLandscape"
          v-ripple
          class="menu-item"
          clickable
        >
          <q-item-section avatar>
            <avatar-image size="2rem" />
          </q-item-section>
          <q-item-section>{{ userName }}</q-item-section>
        </q-item>
        <q-separator
          v-if="drawer && !isLandscape"
          spaced
        />
        <dx-tree-view
          key-expr="id"
          data-structure="plain"
          parent-id-expr="parent_id"
          expand-event="click"
          collapse-icon="none"
          height="auto"
          :data-source="menuEntries"
          @initialized="saveTreeViewIstance"
          @item-click="onItemClick"
          @item-expanded="onItemExpanded"
          @item-collapsed="onItemCollapsed"
        >
          <template #item="data">
            <q-item
              v-ripple
              class="menu-item"
              clickable
              :class="[nodeClass(data.data)]"
              :to="data.data.route"
            >
              <q-item-section
                avatar
              >
                <q-icon
                  :name="data.data.icon"
                />
              </q-item-section>

              <q-item-section>
                {{ t(data.data.code) }}
              </q-item-section>
            </q-item>
          </template>
        </dx-tree-view>
        <q-separator
          v-if="isMobile && !isLandscape"
          spaced
        />
        <q-list v-if="isMobile && !isLandscape">
          <q-item-label header>
            {{ t('common.setting', 2) }}
          </q-item-label>
          <q-item class="q-ml-sm">
            <language-selector width="100%" />
          </q-item>
        </q-list>
      </q-list>
      <q-list
        :class="{'align-center': isMobile && !isLandscape, 'z-top q-pa-sm': true}"
      >
        <q-item
          v-if="drawer && isLandscape"
          v-ripple
          clickable
          @click="onSideBarPinClick"
        >
          <q-item-section avatar>
            <q-icon :name="webSiteStore.sideBarPinned ? 'mdi-pin' : 'mdi-pin-off'" />
          </q-item-section>

          <q-item-section>
            <span v-if="webSiteStore.sideBarPinned">{{ t('common.pin') }}</span>
            <span v-else>{{ t('common.unPin') }}</span>
          </q-item-section>
        </q-item>
        <q-btn
          v-if="drawer && !isLandscape"
          v-ripple
          color="primary"
          label="Logout"
          class="large-btn"
          @click="onLogoutClick"
        />
      </q-list>
    </q-drawer>
  </div>
</template>
<style lang="scss">
.nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.nav-tree {
  overflow: hidden;
}
.nav .dx-treeview-node {
  padding: 0;
}
.nav .dx-treeview-item {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
}
.q-drawer--mini .dx-treeview-item {
  padding: 0 !important;
}
.selected {
  color: $primary !important;
}
.grayed-out {
  color: $grey-9 !important;
}
</style>
