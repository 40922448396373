<script setup>
import { computed } from 'vue'

import DateSelector from '@/components/shared/DateSelector.vue'
import dayjs from 'dayjs'

const props = defineProps({
  modelValue: {
    type: Object || String || null,
    required: true,
  },
  availableDates: {
    type: Array,
    required: false,
  },
  label: {
    type: String,
    required: true,
  },
  end: {
    type: Boolean,
    required: false,
  },
  min: {
    type: String,
    required: false,
  },
})

const emit = defineEmits(['update:modelValue'])

const availableDates = computed(() => {
  if (props.availableDates) {
    return props.availableDates
  }
  if (props.min) {
    return (dateValue) => {
      return dayjs(dateValue).isSameOrAfter(dayjs(props.min), 'day')
    }
  }
  return undefined
})

</script>
<template>
  <q-input
    :model-value="props.end ? props.modelValue.end : props.modelValue.start"
    mask="date"
    class="q-mt-sm"
    outlined
    readonly
    :label="label"
  >
    <template #append>
      <q-icon
        name="mdi-calendar"
        class="cursor-pointer"
      >
        <q-popup-proxy
          cover
          :maximized="false"
          transition-show="scale"
          transition-hide="scale"
        >
          <date-selector
            :model-value="props.modelValue"
            :label="label"
            :end="props.end"
            :available-dates="availableDates"
            close-button
            @update:model-value="emit('update:modelValue', $event)"
          />
        </q-popup-proxy>
      </q-icon>
    </template>
  </q-input>
</template>
