<script setup>
import { useSlots } from 'vue'
import { useVModel } from '@vueuse/core'

const slots = useSlots()
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  message: {
    type: String,
    required: true,
  },
  icon: {
    type: String,
    required: false,
  },
  iconColor: {
    type: String,
    required: false,
    default: 'primary',
  },
  label: {
    type: String,
    required: false,
    default: 'Confirm',
  },
})

const emit = defineEmits(['confirm', 'cancel', 'update:modelValue'])

const value = useVModel(props, 'modelValue', emit)

</script>
<template>
  <q-dialog
    v-model="value"
    persistent
  >
    <q-card>
      <q-card-section
        class="row items-center"
        style="flex-flow: row;"
      >
        <q-avatar
          v-if="props.icon"
          :icon="props.icon"
          :color="props.iconColor"
          text-color="white"
        />
        <span class="q-ml-sm">{{ props.message }}</span>
      </q-card-section>
      <q-card-section v-if="slots">
        <slot />
      </q-card-section>
      <q-card-actions align="right">
        <q-btn
          v-close-popup
          flat
          label="Cancel"
          @click="emit('cancel')"
        />
        <q-btn
          v-close-popup
          flat
          :label="props.label"
          color="primary"
          @click="emit('confirm')"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
