<script setup>
import { ref } from 'vue'
import { useRoute } from 'vue-router'

import { usePortalStore } from '@/stores/portal.store'
import { useWebSiteStore } from '@/stores/web-site.store'

const portalStore = usePortalStore()
const webSiteStore = useWebSiteStore()

const isActivating = ref(false)
const isLoggingIn = ref(false)
const userName = ref('')
const password = ref('')
const repeatPassword = ref('')

const route = useRoute()

const uid = route.query.uid
const token = route.query.token
const lang = route.query.lang
await portalStore.setLanguageAsync(lang, true)

const isActivationButtonEnabled = () => (password.value.trim() && repeatPassword.value.trim() && password.value === repeatPassword.value)

const activateUserAsync = async() => {
  isActivating.value = true
  await new Promise(r => setTimeout(r, 2000))
  const user = (await webSiteStore.activateUserAsync(uid, token, password.value))?.data
  if (user) {
    isLoggingIn.value = true
    userName.value = user.first_name
    await new Promise(r => setTimeout(r, 2000))
    await webSiteStore.loginAsync(user.username, password.value)
  }
  isLoggingIn.value = false
  isActivating.value = false
}

</script>

<template>
  <q-layout
    id="login-layout"
    :key="portalStore.reloadKey"
    view="hHh Lpr lFf"
  >
    <q-page-container>
      <q-page
        class="window-height row justify-center items-center page-background"
      >
        <div class="column text-white justify-center ">
          <div class="row justify-center">
            <q-avatar
              size="7rem"
              square
              class="justify-center"
            >
              <img src="/img/logo.png">
            </q-avatar>
          </div>
          <div class="text-white row justify-center text-h4 q-pt-md">
            <div v-if="!isActivating">
              {{ $t('activation.welcome') }}
            </div>
            <div v-else>
              {{ isLoggingIn ? $t('activation.loggingIn', { username: userName}) : $t('activation.activating') }}
              <q-spinner-dots
                class="q-ml-sm"
                size="2rem"
                color="white"
              />
            </div>
          </div>
          <transition
            v-if="!isActivating"
            appear
            name="slide-fade"
          >
            <div>
              <div
                class="row justify-center q-pt-xl"
              >
                <q-form
                  autocomplete="off"
                  class="q-px-xl"
                  @keydown.enter="activateUserAsync"
                >
                  <q-input
                    v-model="password"
                    for="password"
                    dark
                    color="white"
                    class="pass-input q-mb-lg"
                    type="password"
                    :label="$t('activation.passwordInsert')"
                    lazy-rules
                    :rules="[val => !!val && !!val.trim() || $t('common.required')]"
                  >
                    <template #prepend>
                      <q-icon name="mdi-lock" />
                    </template>
                  </q-input>
                  <q-input
                    v-model="repeatPassword"
                    for="repeatPassword"
                    dark
                    color="white"
                    class="pass-input q-mb-lg"
                    type="password"
                    :label="$t('activation.passwordRepeat')"
                    lazy-rules
                    :rules="[
                      val => !!val && !!val.trim() || $t('common.required'),
                      val => val === password || $t('activation.passwordsDoNotMatch')
                    ]"
                  >
                    <template #prepend>
                      <q-icon name="mdi-lock" />
                    </template>
                  </q-input>
                </q-form>
              </div>
              <div
                v-if="!isActivating"
                class="row justify-center q-pt-xl"
              >
                <q-btn
                  v-ripple
                  rounded=""
                  size="lg"
                  color="primary"
                  class="pass-input text-white"
                  :disabled="!isActivationButtonEnabled()"
                  :label="$t('activation.activate')"
                  @click="activateUserAsync"
                />
              </div>
            </div>
          </transition>
        </div>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<style scoped lang="scss">
@import '@/styles/quasar.variables.scss';

.login-container {
  width: 50rem;
  max-width: 100%;
  margin: 0 auto;
}

.slide-fade-enter-active {
  transition: all 1s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s ease-in;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(30px);
  opacity: 0;
}

.page-background {
  background: linear-gradient(lighten($primary, 40%), darken($primary, 30%));
}

.pass-input {
  width: 20rem;
}
</style>
