import script from "./ProjectDetail.vue?vue&type=script&setup=true&lang=js"
export * from "./ProjectDetail.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QList from 'quasar/src/components/item/QList.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import qInstall from "../../../node_modules/.pnpm/vue-cli-plugin-quasar@5.0.2/node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QList,QItemLabel,QSpace,QDialog});
