<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { DxDataGrid } from 'devextreme-vue/data-grid'
import { useGridRef, useODataSource, useDefaultExport, useDefaultEditing, useDefaultGridOptions, useDefaultDialogTitle } from '@/composables/data-grid'

import { planningApi } from '@/apis/planning.api'
import { usePlanningStore } from '@/stores/planning.store'

import ListItem from '@/components/shared/ListItem.vue'
import ConfirmDialog from '@/components/shared/ConfirmDialog.vue'

const gridId = 'listings-grid'

const i18n = useI18n()

const { gridRef, focusedRow } = useGridRef()
const { defaultDialogTitle } = useDefaultDialogTitle()

const planningStore = usePlanningStore()

const confirmDialogVisibility = ref(false)
const warningRentedEquipments = ref([])

const onRowRemoving = async(e) => {
  warningRentedEquipments.value = []
  e.cancel = planningStore.fetchEquipmentsAsync({ rental_company_id: focusedRow.value.key, active: true }).then(equipments => {
    if (equipments.length > 0) {
      warningRentedEquipments.value = equipments
      confirmDialogVisibility.value = true
      return true
    }
    return false
  })
}

const onDeleteRow = async() => {
  await planningStore.deleteCompanyAsync(focusedRow.value.key)
  confirmDialogVisibility.value = false
  gridRef.value.instance.refresh()
}

const gridDataSource = useODataSource(planningApi.getAbsoluteUrl() + planningApi.companiesUrl)

const { exportOptions, onExporting } = useDefaultExport({ fileName: i18n.t('common.company', 2)})

const columns = [
  {
    dataField: 'name',
    caption: i18n.t('common.name'),
    validationRules: [{ type: 'required' }],
    sortOrder: 'asc'
  },
  {
    dataField: 'description',
    caption: i18n.t('common.description'),
  },
  {
    dataField: 'contact',
    caption: i18n.t('common.contact'),
  },
  {
    dataField: 'is_client',
    caption: i18n.t('common.client'),
    dataType: 'boolean',
  },
  {
    dataField: 'is_rental',
    caption: i18n.t('common.rental'),
    dataType: 'boolean',
  },
]

const editing = useDefaultEditing(i => defaultDialogTitle(i, i18n.t('common.company')), {
  colCount: 1,
  items: [
    {
      itemType: 'group',
      caption: i18n.t('common.mainInfo'),
      colCount: 7,
      items: [
        { dataField: 'name' },
        { dataField: 'description', colSpan: 2 },
        { dataField: 'contact', colSpan: 2 },
        { dataField: 'is_client' },
        { dataField: 'is_rental' },
      ]
    },
  ],
})

const gridOptions = useDefaultGridOptions({
  id: gridId,
  columns: columns,
  dataSource: gridDataSource,
  editing: editing,
  export: exportOptions,
  onExporting: onExporting,
  onRowRemoving: onRowRemoving,
  clearFilterButton: true,
})

</script>
<template>
  <div>
    <confirm-dialog
      v-model="confirmDialogVisibility"
      icon="mdi-alert"
      icon-color="warning"
      :message="`${i18n.t('warning.deleteActiveRentalCompany')}. ${i18n.t('info.wantToContinue')}`"
      @confirm="onDeleteRow"
    >
      <q-list>
        <list-item
          v-for="equipment in warningRentedEquipments"
          :key="equipment.id"
          :item="equipment"
          clickable
          :label="equipment.name"
          :caption="`${i18n.t('common.rentalCompany')}: ${equipment.rental_company_name}`"
        />
      </q-list>
    </confirm-dialog>
    <dx-data-grid
      ref="gridRef"
      v-bind="gridOptions"
    />
  </div>
</template>
