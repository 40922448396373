import { planningApi } from '@/apis/planning.api'
import { defineStore } from 'pinia'
import { ref } from 'vue'

import { useFetchAndStoreAsync } from '@/composables/store'
import i18n from '@/plugins/i18n'

export const initPlanningStoreAsync = async() => {
  const store = usePlanningStore()
  await Promise.all([
    store.fetchCompaniesAsync({}, true),
    store.fetchListingsAsync({}, true),
    store.fetchListingItemsAsync({}, true),
    store.fetchStaffsAsync({}, true),
    store.fetchEquipmentsAsync({}, true),
  ])
}

export const usePlanningStore = defineStore('planning', () => {
  const companies = ref([])
  const equipments = ref([])
  const listingItems = ref([])
  const listings = ref([])
  const projects = ref([])
  const staff = ref([])

  const equipmentConditionEnum = Object.freeze({
    BROKEN: 0,
    NEEDS_REPAIR: 1,
    NEEDS_CLEANING: 2,
    GOOD: 3,
    GREAT: 4,
  })

  const equipmentConditionEnumLabels = [
    'common.condition.broken',
    'common.condition.needsRepair',
    'common.condition.needsCleaning',
    'common.condition.good',
    'common.condition.great',
  ]

  const equipmentConditionColors = ['negative', 'red-6', 'warning', 'positive', 'green-8', 'grey']

  const eventWarningTypeEnum = Object.freeze({
    OVERLAP: 0,
  })

  const projectStatusEnum = Object.freeze({
    PLANNED: 0,
    PRODUCTION: 1,
    POST_PRODUCTION: 2,
  })

  const projectStatusColorEnum = Object.freeze({
    PLANNED: 'green',
    PRODUCTION: 'teal',
    POST_PRODUCTION: 'orange',
  })

  const projectStatusEnumLabels = [
    'common.projectStatus.planned',
    'common.projectStatus.production',
    'common.projectStatus.postProduction',
    'common.projectStatus.completed',
  ]

  const serviceAvailabilityTypeEnum = Object.freeze({
    USUALLY_UNAVAILABLE: 0,
    USUALLY_AVAILABLE: 1,
    ASK: 2
  })

  const serviceAvailabilityEnum = Object.freeze({
    UNAVAILABLE: 0,
    PARTIALLY_AVAILABLE: 1,
    AVAILABLE: 2,
    ASK: 3,
  })

  const serviceTypeEnum = Object.freeze({
    STAFF: 0,
    EQUIPMENT: 1,
  })

  const serviceStatusEnum = Object.freeze({
    AVAILABLE: 0,
    COMMITTED: 1,
    UNAVAILABLE: 2,
  })

  const getVacationProject = (t, serviceIds) => ({
    id: 0,
    name: t('common.vacation'),
    color: '#d05ee3',
    tags: [],
    visible: true,
    services: serviceIds
  })

  const deleteProjectAsync = async(id) => planningApi.deleteProjectAsync(id)
  const deleteCompanyAsync = async(id) => planningApi.deleteCompanyAsync(id)
  const deleteProjectListingItemAsync = async(id) => planningApi.deleteProjectListingItemAsync(id)

  const downloadProjectFileAsync = async(projectId, filename) => planningApi.downloadProjectFileAsync(projectId, filename)

  const fetchCompaniesAsync = async(params, store = false) => useFetchAndStoreAsync(companies, () => planningApi.getCompaniesAsync(params), store)
  const fetchEquipmentsAsync = async(params, store = false) => useFetchAndStoreAsync(equipments, () => planningApi.getEquipmentsAsync(params), store)
  const fetchEquipmentByIdAsync = async(equipmentId, params = {}) => planningApi.getEquipmentByIdAsync(equipmentId, params)
  const fetchEquipmentConditionsByEquipmentIdAsync = async(equipmentId, params = {}) => planningApi.getEquipmentConditionsByEquipmentIdAsync(equipmentId, params)
  const fetchListingItemsAsync = async(params, store = false) => useFetchAndStoreAsync(listingItems, () => planningApi.getListingItemsAsync(params), store)
  const fetchListingsAsync = async(params, store = false) => useFetchAndStoreAsync(listings, () => planningApi.getListingsAsync(params), store)
  const fetchStaffsAsync = async(params, store = false) => useFetchAndStoreAsync(staff, () => planningApi.getStaffsAsync(params), store)
  const fetchProjectByIdAsync = async(id) => planningApi.getProjectByIdAsync(id)
  const fetchProjectsAsync = async(params, store = false) => useFetchAndStoreAsync(projects, () => planningApi.getProjectsAsync(params), store)
  const fetchServicesAsync = async(params) => planningApi.getServicesAsync(params)
  const fetchProjectListingItemsByProjectIdAsync = async(projectId, params = {}) => planningApi.getProjectListingItemsByProjectIdAsync(projectId, params)
  const fetchProjectListingItemServicesByProjectIdAsync = async(projectId, params = {}) => planningApi.getProjectListingItemServicesByProjectIdAsync(projectId, params)
  const fetchEquipmentByUuidAsync = async(uuid) => planningApi.getEquipmentByUuidAsync(uuid)

  const getConditionLabel = (condition) => i18n.global.t(equipmentConditionEnumLabels[condition])
  const getConditionColor = (condition) => equipmentConditionColors[condition]
  const getLocalizedEquipmentConditions = () => equipmentConditionEnumLabels.map((i, idx) => ({ id: idx, name: i18n.global.t(i)}))
  const getEquipmentById = (id) => equipments.value.find(equipment => equipment.id === +id)
  const getLocalizedProjectStatuses = () => projectStatusEnumLabels.map((i, idx) => ({ id: idx, name: i18n.global.t(i)}))
  const getProjectById = (id) => projects.value.find(project => project.id === +id)
  const getProjectStatusColor = (status) => Object.values(projectStatusColorEnum)[status]
  const getProjectByIdAsync = async(id) => projects.value.find(project => project.id === +id) || await fetchProjectByIdAsync(id)
  const getServiceName = (service, short = false) => {
    let name = service?.name
    if (service?.type === serviceTypeEnum.STAFF) {
      name = `${service.name}`
      name += short ? ` ${service.first_name[0]}.` : ` ${service.first_name}`
    }
    return name
  }
  const getStaffById = (id) => staff.value.find(staff => staff.id === +id)
  const getServices = () => [...staff.value, ...equipments.value]

  const upsertEquipmentAsync = async(data) => planningApi.upsertEquipmentAsync(data)
  const upsertEquipmentConditionAsync = async(data) => planningApi.upsertEquipmentConditionAsync(data)
  const upsertProjectAsync = async(data) => planningApi.upsertProjectAsync(data)
  const upsertProjectListingItemAsync = async(data) => planningApi.upsertProjectListingItemAsync(data)
  const upsertProjectListingItemServiceAsync = async(data, params) => planningApi.upsertProjectListingItemServiceAsync(data, params)

  const $reset = () => {
    companies.value = []
    listings.value = []
    listingItems.value = []
    staff.value = []
    equipments.value = []
  }

  return {
    companies,
    equipments,
    equipmentConditionColors,
    equipmentConditionEnum,
    equipmentConditionEnumLabels,
    eventWarningTypeEnum,
    listingItems,
    listings,
    projects,
    projectStatusEnum,
    projectStatusColorEnum,
    projectStatusEnumLabels,
    serviceAvailabilityEnum,
    serviceAvailabilityTypeEnum,
    serviceStatusEnum,
    serviceTypeEnum,
    staff,

    deleteProjectAsync,
    deleteCompanyAsync,
    deleteProjectListingItemAsync,

    downloadProjectFileAsync,

    fetchCompaniesAsync,
    fetchListingsAsync,
    fetchListingItemsAsync,
    fetchProjectByIdAsync,
    fetchProjectsAsync,
    fetchStaffsAsync,
    fetchEquipmentsAsync,
    fetchEquipmentByIdAsync,
    fetchEquipmentByUuidAsync,
    fetchEquipmentConditionsByEquipmentIdAsync,
    fetchServicesAsync,
    fetchProjectListingItemsByProjectIdAsync,
    fetchProjectListingItemServicesByProjectIdAsync,

    getConditionLabel,
    getConditionColor,
    getEquipmentById,
    getServices,
    getLocalizedEquipmentConditions,
    getLocalizedProjectStatuses,
    getProjectById,
    getProjectByIdAsync,
    getProjectStatusColor,
    getServiceName,
    getStaffById,
    getVacationProject,

    upsertEquipmentAsync,
    upsertEquipmentConditionAsync,
    upsertProjectAsync,
    upsertProjectListingItemAsync,
    upsertProjectListingItemServiceAsync,

    $reset,
  }
}, {
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage, paths: [
          'companies',
          'listings',
          'listingItems',
          'tags',
          'staff',
          'equipments',
        ]
      },
      { storage: sessionStorage, paths: ['gridEditItemId'] }
    ],
  },
})
