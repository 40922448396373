<script setup>
import dayjs from 'dayjs'
import { ref, onMounted } from 'vue'
import { usePlanningStore } from '@/stores/planning.store'
import { usePortalStore } from '@/stores/portal.store'

const props = defineProps({
  item: {
    type: Object,
    default: () => {},
  },
  icon: {
    type: String,
    required: false,
    default: 'mdi-checkbox-blank-circle',
  },
  clickable: {
    type: Boolean,
    required: false,
    default: true,
  },
  hideColor: {
    type: Boolean,
    required: false,
  },
  hideDate: {
    type: Boolean,
    required: false,
  },
  hideTime: {
    type: Boolean,
    required: false,
  },
  hideJournalist: {
    type: Boolean,
    required: false,
  },
  hideLabel: {
    type: Boolean,
    required: false,
  },
  hideLocation: {
    type: Boolean,
    required: false,
  },
  hideStatus: {
    type: Boolean,
    required: false,
  },
  date: {
    type: String,
    required: false,
  },
  start: {
    type: String,
    required: false,
  },
  end: {
    type: String,
    required: false,
  },
  showStaff: {
    type: Boolean,
    required: false,
  },
  showEquipment: {
    type: Boolean,
    required: false,
  },
  showTags: {
    type: Boolean,
    required: false,
  },
  deletable: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['click', 'delete'])
const planningStore = usePlanningStore()
const portalStore = usePortalStore()

onMounted(async() => {
  if (props.showTags) {
    await portalStore.fetchTagsAsync({}, true)
  }
})

const projectTags = ref(props.item?.tags ? props.item.tags.map(tag => portalStore.getTagById(tag)) : [])

const projectServices = ref(props.item?.services ? props.item.services.map(service => planningStore.getStaffById(service) || planningStore.getEquipmentById(service)).filter(service => !!service) : [])
const staff = ref(projectServices.value.filter(service => service.type === portalStore.serviceTypeEnum.STAFF))
const equipment = ref(projectServices.value.filter(service => service.type === portalStore.serviceTypeEnum.EQUIPMENT))

const statusLabel = ref(props.item?.status ? planningStore.getLocalizedProjectStatuses()[props.item?.status].name : null)
const statusColor = ref(props.item?.status ? planningStore.getProjectStatusColor(props.item?.status) : null)

</script>
<template>
  <q-item
    :key="props.item.id"
    :clickable="props.clickable"
    class="q-px-none"
    @click="emit('click', props.item)"
  >
    <q-item-section
      :style="{ 'border-left': '4px solid', 'border-color': props.hideColor ? 'transparent' : props.item.color }"
      class="q-pl-sm"
    >
      <q-item-label
        v-if="!props.hideLabel"
      >
        {{ props.item.name }}
      </q-item-label>
      <q-item-label
        v-if="props.item.journalist && !props.hideJournalist"
        caption
      >
        {{ props.item.journalist }}
      </q-item-label>
      <q-item-label
        v-if="props.item.location && !props.hideLocation"
        caption
        class="align-items-middle"
      >
        <q-icon
          name="mdi-map-marker"
          class="q-mr-xs"
        />
        <span>
          {{ props.item.location }}
        </span>
      </q-item-label>
      <q-item-label
        v-if="props.showStaff"
        caption
        class="word-break"
      >
        {{ staff.sort(service => service.name).map(service => (`${planningStore.getServiceName(service)}`)).join(', ') }}
      </q-item-label>
      <q-item-label
        v-if="props.showEquipment"
        caption
        class="word-break"
      >
        {{ equipment.map(service => service.name).sort().join(', ') }}
      </q-item-label>
      <q-item-label
        v-if="props.showTags"
        caption
        class="word-break"
      >
        {{ projectTags.map(tag => tag.name).sort().join(', ') }}
      </q-item-label>
    </q-item-section>
    <q-item-section
      avatar
      class="align-middle align-center q-pa-none"
    >
      <q-item-label
        v-if="!props.hideTime"
        caption
        class="align-items-baseline"
      >
        <q-icon
          name="mdi-clock-outline"
          class="q-mr-xs"
        />
        <span>
          {{ dayjs(props.start || props.item.start).format('HH:mm') }} - {{ dayjs(props.end || props.item.end).format('HH:mm') }}
        </span>
      </q-item-label>
      <q-item-label
        v-if="!props.hideDate"
        caption
        class="align-items-baseline"
      >
        <q-icon
          name="mdi-calendar"
          class="q-mr-xs"
        />
        <span>
          {{ dayjs(props.item.start).format('DD/MM') }} to {{ dayjs(props.item.end).format('DD/MM') }}
        </span>
      </q-item-label>
      <q-item-label
        v-if="props.date"
        caption
        class="align-items-baseline"
      >
        <q-icon
          name="mdi-calendar"
          class="q-mr-xs"
        />
        <span>
          {{ dayjs(props.date).format('DD/MM') }}
        </span>
      </q-item-label>
      <q-item-label
        v-if="props.item.status && !props.hideStatus"
        caption
        class="align-items-middle"
      >
        <q-chip
          size="sm"
          :label="statusLabel"
          :color="statusColor"
        />
      </q-item-label>
    </q-item-section>
    <q-item-section
      v-for="warning in props.item.warnings"
      :key="warning"
      side
    >
      <q-icon
        name="mdi-alert"
        color="warning"
      />
    </q-item-section>
    <q-item-section
      v-if="props.deletable"
      side
    >
      <q-btn
        flat
        dense
        round
        icon="mdi-delete"
        color="negative"
        @click.stop="emit('delete', props.item)"
      />
    </q-item-section>
  </q-item>
</template>
<style lang="scss" scoped>
.word-break {
  white-space: pre-wrap;
  word-break: break-word;
}
</style>
