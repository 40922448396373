<script setup>

import { computed } from 'vue'

import i18n from '@/plugins/i18n'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
    default: true,
  },
  vacations: {
    type: Array,
    required: true,
  },
})

const emit = defineEmits(['update:modelValue'])

const t = i18n.global.t

const itemCounter = computed(() => {
  return props.vacations?.length || 0
})

const onToggleChange = (value) => {
  emit('update:modelValue', value)
}

</script>
<template>
  <div style="display: flex; flex-flow: row; align-items: center;">
    <q-toggle
      :model-value="props.modelValue"
      keep-color
      icon="mdi-beach"
      color="purple"
      @update:model-value="onToggleChange"
    />
    <div :class="{ 'text-grey': !props.modelValue }">
      <span class="text-bold q-pr-xs">
        {{ t('common.vacation', itemCounter) }}
      </span>
      <q-badge
        v-show="itemCounter > 0"
        rounded
        align="top"
        color="blue"
      >
        {{ itemCounter }}
      </q-badge>
    </div>
  </div>
</template>
