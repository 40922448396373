import script from "./SideBar.vue?vue&type=script&setup=true&lang=js"
export * from "./SideBar.vue?vue&type=script&setup=true&lang=js"

import "./SideBar.vue?vue&type=style&index=0&id=1a908640&lang=scss"

const __exports__ = script;

export default __exports__
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../node_modules/.pnpm/vue-cli-plugin-quasar@5.0.2/node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDrawer,QList,QItem,QItemSection,QSeparator,QIcon,QItemLabel,QBtn});qInstall(script, 'directives', {Ripple});
