import BaseApi from './base.api'

class WebSiteApi extends BaseApi {
  availabilityTypesUrl = 'availability-types/'
  clipboardTypesUrl = 'clipboard-types/'
  citiesUrl = 'cities/'
  countriesUrl = 'countries/'
  currenciesUrl = 'currencies/'
  customerTypesUrl = 'customer-types/'
  identityDocumentAuthoritiesUrl = 'identity-authorities/'
  identityDocumentTypesUrl = 'identity-document-types/'
  mechanicsUrl = 'mechanics/'
  menuEntriesUrl = 'menu-entries/'
  paymentTypesUrl = 'payment-types/'
  productTypesUrl = 'product-types/'
  settingsUrl = 'settings/'
  statesUrl = 'states/'
  supplierDivisionTypesUrl = 'supplier-division-types/'
  supplierTypesUrl = 'supplier-types/'
  unitsOfMeasureUrl = 'units-of-measure/'
  urgencyTypesUrl = 'urgency-types/'
  usersUrl = 'users/'
  vatTypesUrl = 'vat-types/'

  constructor() {
    super('web-site/')
  }

  activateUserAsync = (uid, token, password) => this.postAsync(`users/activate`, { uid, token, password })

  getTokenAsync = (username, password) => this.postAsync('token', { username, password }, { credentials: 'include'})

  getFreshTokenAsync = (refreshToken) => this.postAsync('token/refresh', {refresh: refreshToken}, { credentials: 'include' })
  getUserDetailsAsync = (userId) => this.getAsync(`users/${userId}/details`)

  getAvailabilityTypesAsync = (params) => this.getAsync(this.availabilityTypesUrl, params)
  getCitiesAsync = (params) => this.getAsync(this.citiesUrl, params)
  getClipboardTypesAsync = (params) => this.getAsync(this.clipboardTypesUrl, params)
  getCountriesAsync = (params) => this.getAsync(this.countriesUrl, params)
  getCurrenciesAsync = (params) => this.getAsync(this.currenciesUrl, params)
  getCustomerTypesAsync = (params) => this.getAsync(this.customerTypesUrl, params)
  getIdentityDocumentAuthoritiesAsync = (params) => this.getAsync(this.identityDocumentAuthoritiesUrl, params)
  getIdentityDocumentTypesAsync = (params) => this.getAsync(this.identityDocumentTypesUrl, params)
  getMechanicsAsync = (params) => this.getAsync(this.mechanicsUrl, params)
  getMenuEntries = () => this.getAsync(this.menuEntriesUrl)
  getPaymentTypesAsync = (params) => this.getAsync(this.paymentTypesUrl, params)
  getProductTypesAsync = (params) => this.getAsync(this.productTypesUrl, params)
  getSettingsAsync = (params) => this.getAsync(this.settingsUrl, params)
  getStatesAsync = (params) => this.getAsync(this.statesUrl, params)
  getSupplierDivisionTypesAsync = (params) => this.getAsync(this.supplierDivisionTypesUrl, params)
  getSupplierTypesAsync = (params) => this.getAsync(this.supplierTypesUrl, params)
  getUnitsOfMeasureAsync = (params) => this.getAsync(this.unitsOfMeasureUrl, params)
  getUrgencyTypesAsync = (params) => this.getAsync(this.urgencyTypesUrl, params)
  getUsersAsync = (params) => this.getAsync(this.usersUrl, params)
  getVatTypesAsync = (params) => this.getAsync(this.vatTypesUrl, params)
}

export const webSiteApi = new WebSiteApi('web-site')
