import BaseApi from './base.api'
class PortalApi extends BaseApi {
  tagsUrl = 'tags/'

  getVersionAsync = () => this.getAsync('settings/version/')

  getLanguagesAsync = () => this.getAsync('localizations/languages/')

  getLocalizationsAsync = (languageCode) => {
    if (!languageCode) {
      return this.getAsync('localizations/')
    }
    return this.getAsync(`localizations/`, { language: languageCode })
  }

  getTagsAsync = (params) => this.getAsync(this.tagsUrl, params)
}
export const portalApi = new PortalApi('portal/')
