<script setup>
import { computed } from 'vue'
import { useWebSiteStore } from '@/stores/web-site.store.js'
import { stringToColor } from '@/lib/utils.js'

const webSiteStore = useWebSiteStore()

const props = defineProps({
  size: {
    type: String,
    default: '2.5rem',
  },
})

const userAvatarImage = computed(() => {
  if (webSiteStore.user && webSiteStore.user.avatar) {
    return `data:image/png;base64,${webSiteStore.user.avatar}`
  }
  return ''
})

const userAvatarStyle = computed(() => {
  return {'background-color': stringToColor(userAvatarInitials.value) }
})

const userAvatarInitials = computed(() => webSiteStore.getUserInitials())

</script>
<template>
  <div>
    <q-avatar
      v-if="userAvatarImage"
      :size="props.size"
    >
      <img :src="userAvatarImage">
    </q-avatar>
    <q-avatar
      v-else
      :style="userAvatarStyle"
      :size="props.size"
    >
      {{ userAvatarInitials }}
    </q-avatar>
  </div>
</template>
<style lang="scss"></style>
