import script from "./StepperNavigation.vue?vue&type=script&setup=true&lang=js"
export * from "./StepperNavigation.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QStepperNavigation from 'quasar/src/components/stepper/QStepperNavigation.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/.pnpm/vue-cli-plugin-quasar@5.0.2/node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QStepperNavigation,QBtn});
