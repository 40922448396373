import BaseApi from './base.api'

import { useScheduleStore } from '@/stores/schedule.store'

class ScheduleApi extends BaseApi {
  eventsUrl = 'events/'

  getEventsAsync = (params) => this.getAsync(this.eventsUrl, params)
  getEventsByServiceAsync = (serviceId, params) => this.getAsync(this.eventsUrl, {
    ...params,
    service: serviceId
  })
  getServiceAvailabilitiesByServiceAsync = (serviceId, params) => this.getAsync(this.eventsUrl, {
    ...params,
    type: useScheduleStore().eventTypeEnum.SERVICE_AVAILABILITY,
    service: serviceId
  })
  getProjectEventsByServiceAsync = (serviceId, params) => this.getAsync(this.eventsUrl, {
    ...params,
    type: useScheduleStore().eventTypeEnum.PROJECT,
    service: serviceId
  })
  getEventsByDayThenProjectAsync = (params) => this.getAsync(`${this.eventsUrl}by-day-then-project/`, params)

  upsertEventAsync = (data) => this.upsertAsync(this.eventsUrl, data)
  upsertServiceAvailabilityAsync = (data) => this.upsertAsync(this.eventsUrl, {...data, type: useScheduleStore().eventTypeEnum.SERVICE_AVAILABILITY})

  deleteEventAsync = (eventId) => this.deleteAsync(`${this.eventsUrl}${eventId}/`)
}

export const scheduleApi = new ScheduleApi('schedule/')
