import script from "./EventWarningToggle.vue?vue&type=script&setup=true&lang=js"
export * from "./EventWarningToggle.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import qInstall from "../../../node_modules/.pnpm/vue-cli-plugin-quasar@5.0.2/node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QToggle,QBadge});
