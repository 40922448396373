<script setup>
import { useRoute } from 'vue-router'
import ProjectDetail from '@/components/planner/ProjectDetail.vue'

const route = useRoute()
const param = +route.params.id

</script>
<template>
  <project-detail :id="param" />
</template>
