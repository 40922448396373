<script setup>
import { ref } from 'vue'
import { QrcodeStream } from 'vue-qrcode-reader'

const props = defineProps({
  timeout: {
    type: Number,
    default: 10000,
  },
  formats: {
    type: Array,
    default: () => ['qr_code'],
  },
})

const scanningTimeout = ref(null)

const emit = defineEmits(['close', 'detect', 'timeout', 'error'])

const onLoaded = () => {
  scanningTimeout.value = setTimeout(() => {
    emit('timeout')
  }, props.timeout)
}

const onDetect = (code) => {
  clearTimeout(scanningTimeout.value)
  emit('detect', code)
}

const onScanError = (error) => {
  console.error(error)
  isScanning.value = false
  emit('error', error)
}

</script>
<template>
  <qrcode-stream
    :formats="props.formats == [] ? null : props.formats"
    @detect="onDetect"
    @camera-on="onLoaded"
    @error="onScanError"
  />
</template>
