<script setup>
import i18n from '@/plugins/i18n'
import dayjs from 'dayjs'
import { QSpinnerDots } from 'quasar'

import { ref } from 'vue'
import { usePlanningStore } from '@/stores/planning.store'

import { useNotify } from '@/composables/notify'
import { useDetectDevice } from '@/composables/device'

import ConditionSlider from '@/components/shared/ConditionSlider.vue'
import NavigateBack from '@/components/shared/NavigateBack.vue'
import QrcodeScanner from '@/components/shared/QrcodeScanner.vue'

const props = defineProps({
  allowedTagIds: {
    type: Array,
    required: false,
  },
  allowedUuids: {
    type: Array,
    required: false,
  },
  equipmentId: {
    type: Number,
    required: false,
  },
  isProduction: {
    type: Boolean,
    required: false,
  },
})

const t = i18n.global.t
const { isMobile } = useDetectDevice()
const planningStore = usePlanningStore()
const { notifySuccess, notifyError, notifyWarning } = useNotify()

const isProduction = ref(props.isProduction)
const equipment = ref((await planningStore.fetchEquipmentByIdAsync(props.equipmentId)).data)
const isScanning = ref((isProduction.value && equipment.value.status === planningStore.serviceStatusEnum.AVAILABLE) || (!isProduction.value && equipment.value.status === planningStore.serviceStatusEnum.COMMITTED))
const scanningTimeout = ref(null)
const tab = ref('status')
const equipmentConditions = ref([])
const emit = defineEmits(['cancel', 'update'])

let lastCondition = null

const onScan = () => { isScanning.value = true }

const onScanError = (error) => {
  console.error(error)
  notifyError(t('error.qrCodeNotFound'))
  isScanning.value = false
  emit('cancel')
}

const onDetect = async(codes) => {
  clearTimeout(scanningTimeout.value)
  try {
    const decodedValue = codes[0].rawValue
    const checkNotificationDismiss = notifyWarning(`${t('common.qrCodeDetected')}! ${t('common.checking')}`, { spinner: QSpinnerDots })

    const uuidMatch = decodedValue.match(/\?uuid=([a-z0-9-]+)/i)
    if (!uuidMatch || uuidMatch.length < 2) {
      throw new Error(`Unable to extract uuid from QR code decoded value: ${decodedValue}`)
    }

    equipment.value = (await planningStore.fetchEquipmentByUuidAsync(uuidMatch[1]))?.data[0]
    checkNotificationDismiss()

    if ((isProduction.value && props.allowedTagIds && props.allowedTagIds.length && !equipment.value.tags.some(tag => props.allowedTagIds.includes(tag))) || (!isProduction.value && props.allowedUuids && props.allowedUuids.length && !props.allowedUuids.includes(equipment.value.uuid))) {
      notifyWarning(t('warning.equipmentNotInList'))
      emit('cancel')
      return
    }

    switch (equipment.value.status) {
      case isProduction.value && planningStore.serviceStatusEnum.COMMITTED:
        notifyWarning(t('warning.equipmentAlreadyCheckedOut'))
        emit('cancel')
        return
      case !isProduction.value && planningStore.serviceStatusEnum.AVAILABLE:
        notifyWarning(t('warning.equipmentAlreadyCheckedIn'))
        emit('cancel')
        return
      case planningStore.serviceStatusEnum.UNAVAILABLE:
        notifyWarning(t('warning.equipmentUnavailable'))
        emit('cancel')
        return
      default:
        notifySuccess(t('common.equipmentFound'))
        if (!equipment.value.condition) {
          equipment.value.condition = {
            condition: planningStore.equipmentConditionEnum.GOOD,
          }
        }
        lastCondition = equipment.value.condition
    }
  } catch (error) {
    console.error(error)
    notifyError(t('error.qrCodeNotFound'))
    emit('cancel')
    return
  } finally {
    isScanning.value = false
  }
}

const confirmCheckOutIn = async() => {
  await planningStore.upsertEquipmentAsync({
    id: equipment.value.id,
    status: isProduction.value ? planningStore.serviceStatusEnum.COMMITTED : planningStore.serviceStatusEnum.AVAILABLE,
  })
  if (lastCondition.condition !== equipment.value.condition || lastCondition.comment !== equipment.value.condition.comment) {
    await planningStore.upsertEquipmentConditionAsync({
      equipment: equipment.value.id,
      condition: equipment.value.condition.condition,
      comment: equipment.value.condition.comment,
    })
  }
  emit('update')
}

let isHistoryLoaded = false
const onTabChanged = async(tab) => {
  if (tab === 'history' && !isHistoryLoaded) {
    equipmentConditions.value = (await planningStore.fetchEquipmentConditionsByEquipmentIdAsync(equipment.value.id)).data.map(condition => {
      const currentService = condition.service_id ? planningStore.getStaffById(condition.service_id) : null
      return {
        ...condition,
        conditionLabel: t(planningStore.equipmentConditionEnumLabels[condition.condition]),
        createdAt: dayjs(condition.created_at).format('YYYY-MM-DD HH:mm'),
        createdByName: currentService ? planningStore.getServiceName(currentService) : condition.username,
        createdByAvatarUrl: currentService?.avatar_url,
      }
    }).sort((a, b) => dayjs(b.created_at).diff(dayjs(a.created_at)))
    isHistoryLoaded = true
  }
}

const getScannerStyle = () => {
  return {
    position: isMobile.value ? 'fixed' : 'static',
    top: '5rem',
    bottom: '1rem',
    zIndex: 100,
  }
}
</script>
<template>
  <q-card>
    <q-card-section>
      <q-list>
        <navigate-back
          :text="equipment?.name || t('common.scanEquipmentQrCode')"
          @back="emit('cancel')"
        />
      </q-list>
    </q-card-section>
    <qrcode-scanner
      v-if="isScanning"
      :style="getScannerStyle()"
      :timeout="5000"
      @detect="onDetect"
      @timeout="onScanError"
      @error="onScanError"
    />
    <q-card-section
      v-if="!equipment"
      class="flex flex-center"
    >
      <q-btn
        v-if="!isScanning"
        icon="mdi-qrcode-scan"
        size="3rem"
        round
        :disabled="isScanning"
        :loading="isScanning"
        @click="onScan"
      />
    </q-card-section>
    <q-tab-panels
      v-if="!isScanning"
      v-model="tab"
      animated
    >
      <q-tab-panel
        name="status"
        class="fit"
      >
        <q-card-section>
          <q-img
            style="max-height: 20rem;"
            :src="equipment?.avatar_url"
          />
        </q-card-section>
        <q-card-section>
          <condition-slider
            v-model="equipment.condition.condition"
            :label="t('common.condition', 2)"
          />
          <q-input
            v-model="equipment.condition.comment"
            class="q-mt-md"
            type="textarea"
            outlined
            autogrow
            :label="t('common.comment', 2)"
          />
        </q-card-section>
        <q-space />
        <q-card-section
          class="align-center"
        >
          <q-btn
            color="positive"
            class="confirm-button"
            :label="isProduction ? t('common.confirmCheckOut') : t('common.confirmCheckIn')"
            :disabled="equipment.condition == null || ((isProduction && equipment.status !== planningStore.serviceStatusEnum.AVAILABLE) || (!isProduction && equipment.status !== planningStore.serviceStatusEnum.COMMITTED))"
            @click="confirmCheckOutIn"
          />
        </q-card-section>
      </q-tab-panel>
      <q-tab-panel
        name="history"
      >
        <q-scroll-area style="height: 80vh">
          <q-card flat>
            <q-card-section class="q-px-sm q-pt-none">
              <q-timeline>
                <q-timeline-entry
                  v-for="condition in equipmentConditions"
                  :key="condition.id"
                  :title="condition.conditionLabel"
                  :subtitle="`${condition.createdByName} - ${condition.createdAt}`"
                  :avatar="condition.createdByAvatarUrl"
                  :color="planningStore.equipmentConditionColors[condition.condition]"
                >
                  <div>
                    {{ condition.comment }}
                  </div>
                </q-timeline-entry>
              </q-timeline>
            </q-card-section>
          </q-card>
        </q-scroll-area>
      </q-tab-panel>
    </q-tab-panels>
    <div
      v-if="!isScanning"
      class="fixed-bottom white-bg"
    >
      <q-separator />
      <q-tabs
        v-model="tab"
        active-color="primary"
      >
        <q-tab
          name="status"
          :label="t('common.status')"
        />
        <q-tab
          name="history"
          :label="t('common.history')"
          @click="onTabChanged('history')"
        />
      </q-tabs>
    </div>
  </q-card>
</template>
<style lang="scss">
.confirm-button {
  position: fixed;
  bottom: 4rem;
}
</style>
