import Notify from 'quasar/src/plugins/Notify.js';

export const useNotify = () => {
  const notifyInfo = (message, config) =>
    Notify.create({ message: message, color: 'info', icon: 'mdi-information-outline', ...config })

  const notifySuccess = (message, config) =>
    Notify.create({ message: message, color: 'positive', icon: 'mdi-check-circle-outline', ...config })

  const notifyError = (message, config) =>
    Notify.create({ message: message, color: 'negative', icon: 'mdi-alert-outline', ...config })

  const notifyWarning = (message, config) =>
    Notify.create({
      message: message,
      color: 'warning',
      textColor: 'dark',
      icon: 'mdi-alert-circle-outline',
      ...config
    })

  return { notifyInfo, notifySuccess, notifyError, notifyWarning }
}
