<script setup>
import '@/styles/style.scss'
import { ref, onMounted } from 'vue'
import router from '@/router.js'
import { useNotify } from '@/composables/notify'
import i18n from './plugins/i18n'
import { usePortalStore } from '@/stores/portal.store'

const t = i18n.global.t
const { notifyInfo } = useNotify()
const bar = ref(null)
const portalStore = usePortalStore()

const onSoftwareUpdated = (event) => {
  notifyInfo(t('common.softwareUpdated'), {
    timeout: 0,
    actions: [
      {
        label: t('common.reload'),
        handler: () => {
          if (event.detail && event.detail.waiting) {
            event.detail.waiting.postMessage({ type: 'SKIP_WAITING' })
            window.location.reload()
          }
        },
      },
    ],
  })
}

let refreshing = false

document.addEventListener('softwareUpdated', onSoftwareUpdated, { once: true })
navigator.serviceWorker.addEventListener('controllerchange', () => {
  if (refreshing) return
  refreshing = true
  window.location.reload()
})

onMounted(async() => {
  await portalStore.fetchVersionAsync()
  router.beforeEach((to, from, next) => {
    bar.value.start()
    next()
  })
  router.afterEach(() => {
    bar.value.stop()
  })
})

</script>
<template>
  <q-ajax-bar
    ref="bar"
  />
  <Suspense>
    <router-view v-slot="{ Component }">
      <transition
        appear
        mode="out-in"
        duration="500"
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut"
      >
        <component :is="Component" />
      </transition>
    </router-view>
  </Suspense>
</template>
