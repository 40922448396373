<script setup>
import { DxFileUploader } from 'devextreme-vue/file-uploader'
import { ref } from 'vue'

const props = defineProps({
  cellInfo: {
    type: Object,
  },
})

const filesList = ref(props.cellInfo.value || [])

const onValueChanged = (e) => {
  e.value.forEach(file => {
    const reader = new FileReader()
    const fileName = file.name
    reader.onload = (args) => {
      filesList.value.push({
        name: fileName,
        payload: args.target.result
      })
      props.cellInfo.setValue(filesList.value)
    }
    if (file instanceof Blob) {
      if (!filesList.value.find(item => item.name === fileName)) {
        reader.readAsDataURL(file) // convert to base64 string
      }
    }
  })
}

const removeFile = (file) => {
  filesList.value = filesList.value.filter(item => item.name !== file.name)
  props.cellInfo.setValue(filesList.value)
}

const emit = defineEmits({
  download: (e) => true,
})

</script>
<template>
  <div>
    <dx-file-uploader
      accept="image/*, .pdf"
      :multiple="true"
      :show-file-list="false"
      upload-mode="useForm"
      @value-changed="onValueChanged"
    />
    <q-list
      bordered
      separator
    >
      <q-item
        v-for="item in filesList"
        :key="item.name"
        v-ripple
        clickable
      >
        <q-item-section
          side
          top
          @click="removeFile(item)"
        >
          <q-avatar icon="mdi-delete" />
        </q-item-section>
        <q-item-section> {{ item.name }} </q-item-section>
        <q-item-section
          side
          top
          @click="emit('download', { value: item })"
        >
          <q-avatar icon="mdi-download" />
        </q-item-section>
      </q-item>
    </q-list>
  </div>
</template>
