<script setup>

// import { useVModel } from '@vueuse/core'
import dayjs from 'dayjs'
import { computed } from 'vue'

const props = defineProps({
  modelValue: {
    type: [Object, String, null],
    required: true,
  },
  end: {
    type: Boolean,
    required: false,
  },
  label: {
    type: String,
    required: true,
  },
  min: {
    type: String,
    required: false,
  },
})

const emit = defineEmits(['update:modelValue'])
const time = computed(() => dayjs(props.modelValue).format('HH:mm'))

const onTimeUpdate = (val) => {
  let currentDateTime = dayjs(props.modelValue)
  const value = val.split(':')
  currentDateTime = currentDateTime.set('hour', value[0])
  currentDateTime = currentDateTime.set('minute', value[1])
  emit('update:modelValue', currentDateTime.toISOString())
}

const hourOptions = computed(() => {
  if (!props.min) {
    return Array.from({ length: 24 }, (_, i) => i)
  }
  const sameDay = dayjs(props.min).isSame(dayjs(props.modelValue), 'day')
  if (sameDay) {
    const minHour = dayjs(props.min).hour()
    return Array.from({ length: 24 }, (_, i) => i).filter((i) => i >= minHour)
  }
  return Array.from({ length: 24 }, (_, i) => i)
})

const minuteOptions = computed(() => {
  if (!props.min) {
    return Array.from({ length: 60 }, (_, i) => i)
  }
  const sameHour = dayjs(props.min).isSame(dayjs(props.modelValue), 'hour')
  if (sameHour) {
    const minMinute = dayjs(props.min).minute()
    return Array.from({ length: 60 }, (_, i) => i).filter((i) => i >= minMinute)
  }
  return Array.from({ length: 60 }, (_, i) => i)
})

</script>
<template>
  <q-input
    :model-value="time"
    outlined
    readonly
    mask="time"
  >
    <template #append>
      <q-icon
        :name="end ? 'mdi-clock-end' : 'mdi-clock-start'"
        class="cursor-pointer"
      >
        <q-popup-proxy
          cover
          :maximized="false"
          transition-show="scale"
          transition-hide="scale"
        >
          <q-time
            :model-value="time"
            format24h
            :hour-options="hourOptions"
            :minute-options="minuteOptions"
            @update:model-value="onTimeUpdate"
          >
            <div class="row items-center justify-end">
              <q-btn
                v-close-popup
                label="Close"
                color="primary"
                flat
              />
            </div>
          </q-time>
        </q-popup-proxy>
      </q-icon>
    </template>
  </q-input>
</template>
