import { useI18n } from 'vue-i18n'

import { useLookup, useCurrencyOptions } from '@/composables/data-grid'
import { useWebSiteStore } from '@/stores/web-site.store'

export const useCurrencyColumn = (options) => {
  const i18n = useI18n()
  const webSiteStore = useWebSiteStore()

  const defaultOptions = {
    dataField: 'currency',
    referenceField: 'price',
    editors: {},
    setCellValue: (newData, value, currentRowData) => {
      newData[options.dataField] = value
      const currentCurrency = webSiteStore.currencies.find((currency) => currency.id === value)
      if (currentCurrency) {
        options.editors[options.referenceField]?.option('format', useCurrencyOptions({ currency: currentCurrency.code }).format)
        if (currentRowData[options.referenceField]) {
          options.editors[options.referenceField]?.option('value', +(currentRowData[options.referenceField] * currentCurrency.exchange_rate))
        }
      }
    }
  }

  options = { ...defaultOptions, ...options }

  return {
    caption: i18n.t('common.currency'),
    dataField: options.dataField,
    lookup: useLookup({
      dataSource: webSiteStore.currencies,
      valueExpr: 'id',
      displayExpr: 'name',
    }),
    setCellValue: options.setCellValue,
  }
}
