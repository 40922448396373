<script setup>
import i18n from '@/plugins/i18n'

import dayjs from 'dayjs'
import { ref } from 'vue'

import { useNotify } from '@/composables/notify'
import { useWebSiteStore } from '@/stores/web-site.store'
import { useScheduleStore } from '@/stores/schedule.store'

import InputDateSelector from '@/components/shared/InputDateSelector.vue'
import TimeSelector from '@/components/shared/TimeSelector.vue'

const t = i18n.global.t
const scheduleStore = useScheduleStore()
const webSiteStore = useWebSiteStore()

const { notifySuccess } = useNotify()

const props = defineProps({
  modelValue: {
    type: [Object, null],
    required: true,
  },
})

const emit = defineEmits(['update', 'cancel'])
const editItem = ref(props.modelValue)

if (!editItem.value || !editItem.value.start || !editItem.value.end) {
  editItem.value = {
    start: dayjs().toISOString(),
    end: dayjs().add(2, 'hour').toISOString(),
    service: webSiteStore.user.service_id
  }
}

const onAppointmentInserted = async() => {
  await scheduleStore.upsertServiceAvailabilityAsync(editItem.value)
  notifySuccess(t('info.updatedSuccessfully'))
  emit('update', editItem.value)
}
</script>
<template>
  <q-card style="max-width: 80vw;">
    <q-card-section class="bg-primary text-white">
      <span class="text-h6">
        {{ editItem?.id ? t('common.updateVacation') : t('common.createVacation') }}
      </span>
      <q-icon
        name="mdi-airplane-marker"
        size="md"
        class="q-ml-sm"
      />
    </q-card-section>
    <q-card-section v-if="editItem">
      <input-date-selector
        v-model="editItem"
        class="q-mt-sm"
        :label="t('common.startDate')"
      />
      <input-date-selector
        v-model="editItem"
        :label="t('common.endDate')"
        :min="editItem.start"
        end
        class="q-mt-sm"
      />
      <time-selector
        v-model="editItem.start"
        :label="t('common.startTime')"
        from
        class="q-mt-sm"
        @update:model-value="editItem.end = $event"
      />
      <time-selector
        v-model="editItem.end"
        :label="t('common.endTime')"
        :min="editItem.start"
        end
        class="q-mt-sm"
      />
    </q-card-section>
    <q-separator />

    <q-card-actions align="right">
      <q-btn
        flat
        :label="t('common.cancel')"
        color="primary"
        @click="emit('cancel')"
      />
      <q-btn
        :label="t('common.save')"
        color="primary"
        @click="onAppointmentInserted"
      />
    </q-card-actions>
  </q-card>
</template>
