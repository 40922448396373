<script setup>
import { computed } from 'vue'
import { usePortalStore } from '@/stores/portal.store.js'
import DxSelectBox from 'devextreme-vue/select-box'
import i18n from '@/plugins/i18n'
import { toRaw } from 'vue'

const props = defineProps({
  inverted: {
    type: Boolean,
    default: false,
  }
})

const portalStore = usePortalStore()
const t = i18n.global.t

const languages = computed(() => {
  return toRaw(portalStore.languages).map((item) => {
    return {
      id: item.code,
      name: t(item.name)
    }
  })
})

async function onLanguageValueChanged(e) {
  await portalStore.setLanguageAsync(e.value)
  portalStore.incrementReloadKey()
}

</script>
<template>
  <dx-select-box
    id="languageSelector"
    :class="{'inverted': props.inverted}"
    label-mode="hidden"
    value-expr="id"
    display-expr="name"
    :value="portalStore.selectedLanguage"
    :data-source="languages"
    @value-changed="onLanguageValueChanged"
  />
</template>
<style lang="scss">
  #languageSelector {
    &.inverted {
      & input,
      & select {
          color: white !important;
          -webkit-text-fill-color: white !important;
      }

      input.dx-texteditor-input {
        color: white !important;
      }

      &.dx-state-hover {
        background-color: transparent !important;
        color: red !important;
      }

      &.dx-editor-underlined:after {
        content: none !important;
        border-bottom: none !important;
      }

      & .dx-dropdowneditor-icon {
        color: white !important;
      }
    }
  }
</style>
