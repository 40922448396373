
import { paramsSubstituteTranslate } from '@/plugins/i18n'
import { useWebSiteStore } from '@/stores/web-site.store'
import { useNotify } from './notify'

export const useException = () => {
  const processError = async(err) => {
    if (!err.config || !err.config.skipErrorMessage) {
      let message = 'error.generic'
      let params = {}
      let type = 'error'

      if (err) {
        message = err.message || err
      }

      if (err.response && err.response.data) {
        message = err.response.data.message || err.response.data.toString()
        params = err.response.data.params || {}
      }
      if (err.response && err.response.status === 401) {
        const webSiteStore = useWebSiteStore()
        await webSiteStore.logoutAsync()
        return
      } else if (err.response && err.response.status === 404) {
        if (window.config.IS_DEBUG) {
          console.debug(`${err.response.statusText} - ${err.response.config.url}`)
        } else {
          message = 'warning.file_not_found'
          type = 'warning'
        }
      } else if (message.startsWith('error.')) {
        type = 'error'
      } else if (message.startsWith('warning.')) {
        type = 'warning'
      } else {
        message = 'error.generic'
      }
      const { notifyError, notifyWarning } = useNotify()
      if (type === 'warning') {
        notifyWarning(paramsSubstituteTranslate(message, params) || message)
      } else {
        notifyError(paramsSubstituteTranslate(message, params) || message)
      }
    }
  }

  return { processError }
}
