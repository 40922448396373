import axios from 'axios'
import { useException } from '@/composables/exceptions'

// const CSRF_COOKIE_NAME = 'csrftoken'
// const CSRF_HEADER_NAME = 'X-CSRFToken'

const httpApi = axios.create({
  baseURL: window.config.BASE_API,
  timeout: window.config.TIMEOUT,
})

httpApi.firstTime = true

// // Request Interceptor
httpApi.interceptors.request.use(config => {
  return config
}, error => {
  // showMessage(error, messageType.ERROR)
})

// Response Interceptor
httpApi.interceptors.response.use(
  response => {
    if (response.status >= 400) {
      if (!response.config.skipErrorMessage) {
        // showMessage(responseData.message, messageType.ERROR)
      }

      return Promise.reject('error')
    } else {
      return response
    }
  },
  error => {
    console.error(error)
    const { processError } = useException()
    processError(error)

    if (error.response && error.response.config.responseType === 'blob') {
      return new Promise((resolve, reject) => {
        const blb = new Blob([error.response.data])
        const reader = new FileReader()
        reader.readAsText(blb)
        reader.addEventListener('loadend', (e) => {
          error.response.data = JSON.parse(e.srcElement.result)
          processError(error)
          reject(error)
        })
      })
    } else {
      processError(error)
      return Promise.reject(error)
    }
  }
)

export const setCommonHeader = (header, value) => {
  httpApi.defaults.headers.common[header] = value
}

export const setUserDataFilterHeader = (value) => setCommonHeader('X-User-Data-Filter', value)

export const setAuthorizationToken = (token) => {
  !token ? setCommonHeader('Authorization', null) : setCommonHeader('Authorization', `Bearer ${token}`)
}

export const setAcceptLanguageHeader = (language) => {
  httpApi.defaults.headers.common['Accept-Language'] = language
}

export default httpApi
